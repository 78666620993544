import {of as observableOf, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TranslateService} from '@ngx-translate/core';

export class WorkHourPeriodGenerateEntriesExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (component instanceof AbstractGenericGridComponent) {
      return this.doGenerate(component);
    }

    return observableOf({status: false, content: 'Not configurated correctly!' });
  }

  public doGenerate(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    const employee = this.getCurrentFilterValue(component);
    const year = this.getFilterValue(component, 'year');
    const month = this.getFilterValue(component, 'month');

    return this.genericCrudService.customPost('phoenix/workhourperiodentries/import?year=' + (year || '') + '&month=' + (month || '') + '&leasedEmployee=' + (employee ? employee.id : ''), {})
      .pipe(
        map(() => {
          this.injector.get(MessageGrowlService).info(
            this.injector.get(TranslateService).instant('WORK_HOUR.ENTRIES_GENERATED')
          );

          return { status: true, content: ''};
        })
      );
  }

  private getFilterValue(component: AbstractGenericGridComponent, item: string): string|null {
    return component.gridFilters[item] && component.gridFilters[item].value ? component.gridFilters[item].value : null;
  }

  private getCurrentFilterValue(component: AbstractGenericGridComponent): any|null {
    return component.gridFilters.leasedEmployee && component.gridFilters.leasedEmployee.value &&
      component.gridFilters.leasedEmployee.value[0] ?
      component.gridFilters.leasedEmployee.value[0] :
      null;
  }
}
