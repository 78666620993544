import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {Entity} from '../../../../helpers/entity';
import {ElementInputAssociation} from '../../../models/element';
import {EntityDirtyStoreService} from '../../../../content-renderer/services/entity-dirty-store.service';
import {EntityStatus} from '../../../../services/entity/entity-status';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';
import {ElementInputDate, ElementInputText} from '../../../models';
import {TranslateService} from '@ngx-translate/core';
import {DateHelper} from '../../../../helpers/date.helper';

interface SubAssignment {
  assignment: any;
  preCalculation: any;
  job: any;
  currentContract: any;
  selectedWorkingTimeModel: any;
  workplace: any;
  workDescription: string;
  contactPerson: any;
  contactPhoneNumber: string;
  meetingAddressLine: string;
  validFrom: any;
  validTo: any;
  weekday: any;
  selectedWorkingTimeDay: any;
  _embedded: any;
}

export class SetupSubAssignmentFormActionHandler extends AbstractFormActionHandler {

  public entityHasBeenChanged = false;
  private hasOtherSubAssignments = false;

  public constructor(
    private genericCrudService: GenericCrudService,
    private entityDirtyStore: EntityDirtyStoreService,
    private entityManager: EntityManagerService,
    private translateService: TranslateService
  ) {
    super();
  }

  public handleAction(): void {
    const subAssignment: SubAssignment = this.getEntity();

    if (subAssignment) {
      subAssignment._embedded = subAssignment._embedded || {};

      const assignmentId = Entity.getValue(subAssignment, 'assignment.id') || Entity.getValue(subAssignment, 'assignment');

      if (assignmentId) {
        this.setupValidFrom(subAssignment);
        this.genericCrudService.getEntity('phoenix/assignments', +assignmentId, null, {embedded: 'none'}).subscribe((assignment: any) => {
          this.genericCrudService.get(`phoenix/subassignments?assignment=${assignmentId}&embedded=none`).subscribe((tempSubAssignments: any|null) => {
            if (tempSubAssignments instanceof Array && tempSubAssignments.length > 0) {
              this.hasOtherSubAssignments = true;
            }
            subAssignment.assignment = assignment;
            subAssignment._embedded.assignment = assignment;

            this.setupPreCalculation(subAssignment)
              .setupSelectedWorkingTimeModel(subAssignment)
              .setupMeetingContactPerson(subAssignment)
              .setupContactPhoneNumber(subAssignment)
              .setupMeetingAddressLine(subAssignment)
              .setupWorkplace(subAssignment);

            this.finallySetupCurrentContractAndPatchForm(subAssignment);
          });
        });
      }
    }
  }

  private changeFormValues(subAssignment: SubAssignment): this {
    const components = this.formService.getComponents();

    this.getComponent().patchFormGroupDisabled();
    this.getComponent().patchFormGroupValues();

    for (const component of components) {
      if (component.element && component.element.datamodelField && (
        component.element instanceof ElementInputAssociation
        || component.element instanceof ElementInputDate
        || component.element instanceof ElementInputText
      )) {
          component.setupValue();
      }
    }

    this.entityDirtyStore.store(subAssignment, {
      success: ((isLocked) => {
        subAssignment[EntityStatus.ENTITY_CHANGED_FLAG] = true;

        this.getComponent().recheckToolbarItems();
      })
    });

    return this;
  }

  private finallySetupCurrentContractAndPatchForm(subAssignment: SubAssignment): this {

    if (this.canChangeCurrentContract(subAssignment)) {
      const validFrom = this.parseDate(subAssignment, 'validFrom'),
        validTo = this.parseDate(subAssignment, 'validTo'),
        leasedEmployee = this.getLeasedEmployeeFromAssignment(subAssignment);

      this.genericCrudService.get(
        `phoenix/contractofemployments/leasedemployee/${leasedEmployee.id}/validfrom/${validFrom}/validto/${validTo}`
      ).subscribe((contractOfEmployment: any|null) => {
        if (contractOfEmployment) {
          subAssignment.currentContract = contractOfEmployment;
          subAssignment._embedded.currentContract = contractOfEmployment;

          this.setupSelectedModelDayAndPatchForm(subAssignment);
        }
      });
    } else if (!this.canChangeCurrentContract(subAssignment) && this.entityHasBeenChanged) {
      this.setupSelectedModelDayAndPatchForm(subAssignment);
    }

    return this;
  }

  private setupSelectedModelDayAndPatchForm(subAssignment: SubAssignment): this {
    if (subAssignment.selectedWorkingTimeModel && subAssignment.validFrom) {
      let date = subAssignment.validFrom;
      if (typeof date == 'string') {
        date = new Date(date);
      }
      const weekDayShort = this.translateService.instant('COMMON.DATE.DAY_NAMES_MIN_BY_NUMBER.' + date.getDay());
      this.genericCrudService.getEntityBy(`phoenix/selectedworkingtimemodeldays`, 'notes', weekDayShort, {'selectedWorkingTimeModel': subAssignment.selectedWorkingTimeModel}
      ).subscribe((selectedWorkingTimeDay: any | null) => {
        if (selectedWorkingTimeDay) {
          subAssignment.selectedWorkingTimeDay = selectedWorkingTimeDay;
          subAssignment._embedded.selectedWorkingTimeDay = selectedWorkingTimeDay;
        }
        this.changeFormValues(subAssignment);
      });
    } else {
      this.changeFormValues(subAssignment);
    }
    return this;
  }

  private setupPreCalculation(subAssignment: SubAssignment): this {

    if (this.canChangePreCalculation(subAssignment)) {
      const preCalculation = this.getPreCalculationFromAssignment(subAssignment);

      subAssignment.preCalculation = preCalculation;
      subAssignment._embedded.preCalculation = preCalculation;

      this.entityHasBeenChanged = true;
    }

    return this;
  }

  private setupValidFrom(subAssignment: SubAssignment): this {

    if (this.canChangeValidFrom(subAssignment)) {

      subAssignment.validFrom = this.getValidFromAssignment(subAssignment);
      if (subAssignment.validFrom) {
        let date = subAssignment.validFrom;
        if (typeof date == 'string') {
          date = new Date(date);
        }
        const weekDay = date.getDay();
        subAssignment.weekday = this.translateService.instant('COMMON.DATE.DAY_NAMES_BY_NUMBER.' + weekDay);
      }
      this.entityHasBeenChanged = true;
    }

    return this;
  }

  private setupSelectedWorkingTimeModel(subAssignment: SubAssignment): this {

    if (this.canChangeSelectedWorkingTimeModel(subAssignment)) {
      const selectedWorkingTimeModel = this.getSelectedWorkingTimeModelFromAssignment(subAssignment);

      subAssignment.selectedWorkingTimeModel = selectedWorkingTimeModel;
      subAssignment._embedded.selectedWorkingTimeModel = selectedWorkingTimeModel;

      this.entityHasBeenChanged = true;
    }

    return this;
  }

  private setupWorkplace(subAssignment: SubAssignment): this {

    if (this.canChangeWorkplace(subAssignment)) {
      const workplace = this.getWorkplaceFromAssignment(subAssignment);

      subAssignment.workplace = workplace;
      subAssignment.workDescription = workplace.description;
      this.entityManager.persist(this.getEntity(), {property: 'workDescription', newValue: workplace.description});
      subAssignment._embedded.workplace = workplace;

      this.entityHasBeenChanged = true;
    }

    return this;
  }

  private setupMeetingContactPerson(subAssignment: SubAssignment): this {

    if (this.canChangeMeetingContactPerson(subAssignment)) {
      const contactPerson = this.getContactPersonFromAssignment(subAssignment);

      subAssignment.contactPerson = contactPerson;
      subAssignment._embedded.contactPerson = contactPerson;

      this.entityHasBeenChanged = true;
    }

    return this;
  }

  private setupContactPhoneNumber(subAssignment: SubAssignment): this {

    if (this.canChangeContactPhoneNumber(subAssignment)) {
      const contactPhoneNumber = this.getContactPhoneNumberFromAssignment(subAssignment);

      subAssignment.contactPhoneNumber = contactPhoneNumber;
      this.entityManager.persist(this.getEntity(), {property: 'contactPhoneNumber', newValue: contactPhoneNumber});

      this.entityHasBeenChanged = true;
    }

    return this;
  }

  private canChangeMeetingContactPerson(subAssignment: SubAssignment): boolean {
    const contactPerson = this.getContactPersonFromAssignment(subAssignment);
    let meetingContactPerson = Entity.getValue(subAssignment, 'contactPerson')
      || Entity.getValueInEmbedded(subAssignment, 'contactPerson');

    if (meetingContactPerson && meetingContactPerson instanceof Object && Object.keys(meetingContactPerson).length === 0) {
      meetingContactPerson = null;
    }

    return subAssignment && !meetingContactPerson && contactPerson;
  }

  private canChangeContactPhoneNumber(subAssignment: SubAssignment): boolean {
    const contactPhoneNumber = this.getContactPhoneNumberFromAssignment(subAssignment);
    let subAssignmentContactPhoneNumber = Entity.getValue(subAssignment, 'contactPhoneNumber')
      || Entity.getValueInEmbedded(subAssignment, 'contactPhoneNumber');

    if (subAssignmentContactPhoneNumber && subAssignmentContactPhoneNumber instanceof Object && Object.keys(subAssignmentContactPhoneNumber).length === 0) {
      subAssignmentContactPhoneNumber = null;
    }

    return subAssignment && !subAssignmentContactPhoneNumber && contactPhoneNumber;
  }

  private setupMeetingAddressLine(subAssignment: SubAssignment): this {

    if (this.canChangeMeetingAddressLine(subAssignment)) {
      const address = this.getAddressFromAssignment(subAssignment);

      subAssignment.meetingAddressLine = address;
      this.entityManager.persist(this.getEntity(), {property: 'meetingAddressLine', newValue: address});

      this.entityHasBeenChanged = true;
    }

    return this;
  }

  private canChangeMeetingAddressLine(subAssignment: SubAssignment): boolean {
    const address = this.getAddressFromAssignment(subAssignment);
    const meetingAddressLine = Entity.getValue(subAssignment, 'meetingAddressLine');

    return subAssignment && !meetingAddressLine && address;
  }

  private canChangeCurrentContract(subAssignment: SubAssignment): boolean {
    const leasedEmployee = this.getLeasedEmployeeFromAssignment(subAssignment);
    let currentContract = Entity.getValue(subAssignment, 'currentContract') || Entity.getValueInEmbedded(subAssignment, 'currentContract');

    if (currentContract && currentContract instanceof Object && Object.keys(currentContract).length === 0) {
      currentContract = null;
    }

    return subAssignment && !currentContract &&
      this.areDatesValid(subAssignment) && leasedEmployee;
  }

  private canChangePreCalculation(subAssignment: SubAssignment): boolean {
    let subAssignmentPreCalculation = Entity.getValue(subAssignment, 'preCalculation') ||
        Entity.getValueInEmbedded(subAssignment, 'preCalculation');

    if (subAssignmentPreCalculation && subAssignmentPreCalculation instanceof Object &&
      Object.keys(subAssignmentPreCalculation).length === 0
    ) {
      subAssignmentPreCalculation = null;
    }

    const assignmentPreCalculation = this.getPreCalculationFromAssignment(subAssignment);

    return !subAssignmentPreCalculation && assignmentPreCalculation;
  }

  private canChangeValidFrom(subAssignment: SubAssignment): boolean {
    if (this.hasOtherSubAssignments) {
      return false;
    }

    let subAssignmentValidFrom = Entity.getValue(subAssignment, 'validFrom');

    if (!subAssignmentValidFrom || !(subAssignmentValidFrom instanceof Date || DateHelper.isIso(subAssignmentValidFrom))) {
      subAssignmentValidFrom = null;
    } else {
      return false;
    }

    const validFrom = this.getValidFromAssignment(subAssignment);

    return !subAssignmentValidFrom && validFrom;
  }

  private canChangeSelectedWorkingTimeModel(subAssignment: SubAssignment): boolean {
    let subAssignmentSelectedWorkingTimeModel = Entity.getValue(subAssignment, 'selectedWorkingTimeModel') ||
        Entity.getValueInEmbedded(subAssignment, 'selectedWorkingTimeModel');
    const assignmentSelectedWorkingTimeModel = this.getSelectedWorkingTimeModelFromAssignment(subAssignment);

    if (subAssignmentSelectedWorkingTimeModel && subAssignmentSelectedWorkingTimeModel instanceof Object &&
      Object.keys(subAssignmentSelectedWorkingTimeModel).length === 0
    ) {
      subAssignmentSelectedWorkingTimeModel = null;
    }

    return !subAssignmentSelectedWorkingTimeModel && assignmentSelectedWorkingTimeModel;
  }

  private canChangeWorkplace(subAssignment: SubAssignment): boolean {
    let subAssignmentWorkplace = Entity.getValue(subAssignment, 'workplace') ||
      Entity.getValueInEmbedded(subAssignment, 'workplace');

    const assignmentWorkplace = this.getWorkplaceFromAssignment(subAssignment);

    if (subAssignmentWorkplace && subAssignmentWorkplace instanceof Object &&
      Object.keys(subAssignmentWorkplace).length === 0
    ) {
      subAssignmentWorkplace = null;
    }

    return !subAssignmentWorkplace && assignmentWorkplace;
  }

  private areDatesValid(subAssignment: SubAssignment): boolean {
    let validFrom = subAssignment.validFrom,
      validTo = subAssignment.validTo;

    if (validFrom && !(validFrom instanceof Date)) {
      validFrom = new Date(validFrom);
    }

    if (validTo && !(validTo instanceof Date)) {
      validTo = new Date(validTo);
    }

    return validFrom && validFrom instanceof Date &&
      validTo && validTo instanceof Date &&
      validFrom < validTo;
  }

  private parseDate(subAssignment: SubAssignment, property: string): string {
    let subAssignmentDateValue = subAssignment[property];

    if (subAssignmentDateValue instanceof Date) {
      subAssignmentDateValue = subAssignmentDateValue.toISOString().replace(/\.\d+Z/, 'Z');
    }

    return subAssignmentDateValue;
  }


  private getLeasedEmployeeFromAssignment(subAssignment: SubAssignment): any|null {
    return Entity.getValue(subAssignment, 'assignment.leasedEmployee') ||
      Entity.getValueInEmbedded(subAssignment, 'assignment.leasedEmployee');
  }

  private getContactPersonFromAssignment(subAssignment: SubAssignment): any|null {
    return Entity.getValue(subAssignment, 'assignment.inquiryPosition.contactPerson') ||
      Entity.getValueInEmbedded(subAssignment, 'assignment.inquiryPosition.contactPerson');
  }

  private getContactPhoneNumberFromAssignment(subAssignment: SubAssignment): any|null {
    return Entity.getValue(subAssignment, 'assignment.inquiryPosition.contactPhoneNumber') ||
      Entity.getValueInEmbedded(subAssignment, 'assignment.inquiryPosition.contactPhoneNumber');
  }

  private getAddressFromAssignment(subAssignment: SubAssignment): any|null {
    return Entity.getValue(subAssignment, 'assignment.inquiryPosition.assignmentAddress') ||
      Entity.getValueInEmbedded(subAssignment, 'assignment.inquiryPosition.assignmentAddress');
  }

  private getValidFromAssignment(subAssignment: SubAssignment): any|null {
    return Entity.getValue(subAssignment, 'assignment.validFrom') ||
      Entity.getValueInEmbedded(subAssignment, 'assignment.validFrom');
  }

  private getSubAssignmentsFromAssignment(subAssignment: SubAssignment): any|null {
    return Entity.getValue(subAssignment, 'assignment.subAssignments') ||
      Entity.getValueInEmbedded(subAssignment, 'assignment.subAssignments');
  }

  private getPreCalculationFromAssignment(subAssignment: SubAssignment): any|null {
    return Entity.getValue(subAssignment, 'assignment.inquiryPosition.preCalculation') ||
      Entity.getValueInEmbedded(subAssignment, 'assignment.inquiryPosition.preCalculation');
  }

  private getSelectedWorkingTimeModelFromAssignment(subAssignment: SubAssignment): any|null {
    return Entity.getValue(subAssignment, 'assignment.inquiryPosition.selectedWorkingTimeModel') ||
      Entity.getValueInEmbedded(subAssignment, 'assignment.inquiryPosition.selectedWorkingTimeModel');
  }

  private getWorkplaceFromAssignment(subAssignment: SubAssignment): any|null {
    return Entity.getValue(subAssignment, 'assignment.inquiryPosition.workplace') ||
      Entity.getValueInEmbedded(subAssignment, 'assignment.inquiryPosition.workplace');
  }
}
