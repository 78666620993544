import {Component, OnInit} from '@angular/core';
import {GenericCrudService} from '../../services/generic-crud.service';
import {PollingService} from '../../../core/service/polling/polling.service';
import {environment} from '../../../../environments';
import {JobContainerService} from '../../../core/job-runner/job-container.service';
import {LocationService} from '../../services/location.service';
import {User} from '../../services/user/user';
import {ModulesStateService} from '../../content-renderer/services/modules-state.service';
import {LoggerService} from '../../content-renderer/services/logger/logger.service';
import {Branch} from '../../services/branch/branch';
import {UserSessionService} from '../../../core/service/user-session.service';
import {AuthenticationService} from '../../../core/authentication/authentication.service';

class TodoCounterData {
  countMy = 0;
  countGroup = 0;
  countICreated = 0;
  countBranchOffice = 0;
  countQuestionnaire = 0;
}

export enum TodoCounterContext {
  countMy = 1,
  countGroup = 2,
  countICreated = 3,
  countBranchOffice = 4,
  countQuestionnaire = 5
}

@Component({
  selector: 'app-todo-counter',
  templateUrl: './todo-counter.component.html',
  styleUrls: ['./todo-counter.component.scss']
})
export class TodoCounterComponent implements OnInit {

  public todoCounterData: TodoCounterData = new TodoCounterData();
  public user: User = null;

  public constructor(
    protected readonly polling: PollingService,
    protected readonly genericCrudService: GenericCrudService,
    protected readonly jobContainerService: JobContainerService,
    protected readonly locationService: LocationService,
    protected readonly modulesStateService: ModulesStateService,
    protected readonly logger: LoggerService,
    protected readonly userSession: UserSessionService,
    protected readonly authenticationService: AuthenticationService
  ) {
  }

  public ngOnInit(): void {
    this.startPolling();
  }

  public openModule(context: TodoCounterContext): void {
    const value = this.getTotalValue(context),
      todoUrl = this.getModuleUrl(context);

    if (todoUrl === null) {
      this.logger.error(`Module not defined for memo counter context ${context}`);
    }

    if (value !== 0 && todoUrl !== null) {
      this.doOpenModule(todoUrl);
    }
  }

  private doOpenModule(moduleUrl: string): void {
    this.locationService.getRouter().navigate([moduleUrl]).then();
  }

  private getModuleUrl(context: TodoCounterContext): string {
    let todoUrl = null;

    switch (context) {
      case TodoCounterContext.countMy:
        todoUrl = environment.myTodoModuleUrl || null;
        break;
      case TodoCounterContext.countGroup:
        todoUrl = environment.groupTodoModuleUrl || null;
        break;
      case TodoCounterContext.countICreated:
        todoUrl = environment.iCreatedTodoModuleUrl || null;
        break;
      case TodoCounterContext.countBranchOffice:
        todoUrl = environment.branchOfficeTodoModuleUrl || null;
        break;
      case TodoCounterContext.countQuestionnaire:
        todoUrl = environment.questionnaireTodoModuleUrl || null;
        break;
    }

    return todoUrl;
  }

  private getTotalValue(context: TodoCounterContext): number {
    let totalValue = 0;

    switch (context) {
      case TodoCounterContext.countMy:
        totalValue = this.todoCounterData.countMy;
        break;
      case TodoCounterContext.countGroup:
        totalValue = this.todoCounterData.countGroup;
        break;
      case TodoCounterContext.countICreated:
        totalValue = this.todoCounterData.countICreated;
        break;
      case TodoCounterContext.countBranchOffice:
        totalValue = this.todoCounterData.countBranchOffice;
        break;
      case TodoCounterContext.countQuestionnaire:
        totalValue = this.todoCounterData.countQuestionnaire;
        break;
    }

    return totalValue;
  }

  private getBackendUrl(context: TodoCounterContext): string {
    let todoUrl = null;

    switch (context) {
      case TodoCounterContext.countMy:
        todoUrl = environment.myTodoBackendUrl || null;
        break;
      case TodoCounterContext.countGroup:
        todoUrl = environment.groupTodoBackendUrl || null;
        break;
      case TodoCounterContext.countICreated:
        todoUrl = environment.iCreatedTodoBackendUrl || null;
        break;
      case TodoCounterContext.countBranchOffice:
        todoUrl = environment.branchOfficeTodoBackendUrl || null;
        break;
      case TodoCounterContext.countQuestionnaire:
        todoUrl = environment.questionnaireTodoBackendUrl || null;
        break;
    }

    return this.parseApiParameters(todoUrl) + '&onlyCount=1';
  }

  private startPolling(): void {
    const urls = [
      'countMy',
      'countGroup',
      'countBranchOffice'
    ];

    for (const url of urls) {
      this.polling
        .start(
          url,
          {
            observable: this.genericCrudService.get(this.getBackendUrl(TodoCounterContext[url])),
            seconds: 60
          }
        )
        .subscribe((data) => {
          this.todoCounterData[url] = data.total;
        });
    }
  }

  private parseApiParameters(api: string): string {
    let parsedApi = api;

    if (api.indexOf('__CURENT_USER__')) {
      parsedApi = parsedApi.replace('__CURENT_USER__', this.authenticationService.currentUser.id.toString());
    }

    if (api.indexOf('__CURRENT_BRANCH_OFFICE_ID__') && this.userSession.get(Branch.LOCAL_STORAGE_NAME).id) {
      parsedApi = parsedApi.replace('__CURRENT_BRANCH_OFFICE_ID__', this.userSession.get(Branch.LOCAL_STORAGE_NAME).id.toString());
    }

    return parsedApi;
  }
}

