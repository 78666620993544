/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./employee-overview.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../../core/executor/executor.service";
import * as i7 from "../../../../../core/executor/service/executor-actions/executor-actions.service";
import * as i8 from "../../../services/generic/generic-element-validation-execution-steps-factory";
import * as i9 from "../../../../../core/executor/factory/execution-step-factory.service";
import * as i10 from "./employee-overview.component";
import * as i11 from "../../../services/component-highlight-stack.service";
import * as i12 from "../../../services/entity-data-store.service";
import * as i13 from "../../../services/modules-state.service";
import * as i14 from "../../../../validators/services/entity-validator";
import * as i15 from "../../../../services/generic-crud.service";
import * as i16 from "../../../../../core/service/user-session.service";
import * as i17 from "../../../../services/permission/permission.service";
var styles_EmployeeOverviewComponent = [i0.styles];
var RenderType_EmployeeOverviewComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmployeeOverviewComponent, data: {} });
export { RenderType_EmployeeOverviewComponent as RenderType_EmployeeOverviewComponent };
function View_EmployeeOverviewComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "td", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(4, { background: 0 }), i1.ɵdid(5, 933888, null, 0, i3.DefaultStyleDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgStyleImpl, i5.DomSanitizer, [6, i2.NgStyle], [2, i4.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { background: 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "title"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " ", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "span", [["class", "date"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""])), i1.ɵppd(12, 2)], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, _v.context.$implicit.value.color); _ck(_v, 3, 0, currVal_0); var currVal_1 = _ck(_v, 6, 0, _v.context.$implicit.value.color); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.value.firstName; var currVal_3 = _v.context.$implicit.value.lastName; _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v.parent.parent.parent.parent, 0), _v.context.$implicit.value.contractEntryDate.date, "MM.dd.yyyy")); _ck(_v, 11, 0, currVal_4); }); }
function View_EmployeeOverviewComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "td", [["class", "width-200 align-top"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "table", [["class", "fullWidth"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_EmployeeOverviewComponent_4)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.KeyValuePipe, [i1.KeyValueDiffers])], function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.context.$implicit.value.employees)); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.value.primaryName; _ck(_v, 3, 0, currVal_0); }); }
function View_EmployeeOverviewComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "table", [["class", "margin-5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "th", [], [[8, "colSpan", 0]], null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", " ", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_EmployeeOverviewComponent_3)), i1.ɵdid(5, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.KeyValuePipe, [i1.KeyValueDiffers])], function (_ck, _v) { var currVal_3 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_v.context.$implicit.value.customers)); _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.colspanLength(_v.context.$implicit); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.value.firstName; var currVal_2 = _v.context.$implicit.value.lastName; _ck(_v, 2, 0, currVal_1, currVal_2); }); }
function View_EmployeeOverviewComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "td", [], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(7, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(8, { background: 0 }), i1.ɵdid(9, 933888, null, 0, i3.DefaultStyleDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgStyleImpl, i5.DomSanitizer, [6, i2.NgStyle], [2, i4.SERVER_TOKEN], i1.PLATFORM_ID], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(10, { background: 0 })], function (_ck, _v) { var currVal_2 = _ck(_v, 8, 0, _v.context.$implicit.color); _ck(_v, 7, 0, currVal_2); var currVal_3 = _ck(_v, 10, 0, _v.context.$implicit.color); _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.aku; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.aiigew; _ck(_v, 4, 0, currVal_1); }); }
function View_EmployeeOverviewComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_EmployeeOverviewComponent_2)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i2.KeyValuePipe, [i1.KeyValueDiffers]), (_l()(), i1.ɵeld(4, 0, null, null, 11, "table", [["class", "margin-5 legend-table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "th", [], [[8, "colSpan", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Legend "])), (_l()(), i1.ɵeld(7, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["AK\u00DC"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["AIIGEW"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "td", [["class", "width-200"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Farbe"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmployeeOverviewComponent_5)), i1.ɵdid(15, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.entities)); _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.legendData; _ck(_v, 15, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = 3; _ck(_v, 5, 0, currVal_1); }); }
export function View_EmployeeOverviewComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.DatePipe, [i1.LOCALE_ID]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EmployeeOverviewComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entities; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EmployeeOverviewComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "app-custom-employee-overview", [], null, [[null, "mouseover"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("mouseover" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onMouseOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseLeave($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_EmployeeOverviewComponent_0, RenderType_EmployeeOverviewComponent)), i1.ɵprd(512, null, i6.ExecutorService, i6.ExecutorService, [i7.ExecutorActionsService]), i1.ɵprd(512, null, i8.GenericElementValidationExecutionStepsFactory, i8.GenericElementValidationExecutionStepsFactory, [i9.ExecutionStepFactoryService]), i1.ɵdid(3, 245760, null, 0, i10.EmployeeOverviewComponent, [i11.ComponentService, i1.ViewContainerRef, i12.EntityDataStoreService, i13.ModulesStateService, i6.ExecutorService, i8.GenericElementValidationExecutionStepsFactory, i14.EntityValidator, i15.GenericCrudService, i16.UserSessionService, i17.PermissionService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var EmployeeOverviewComponentNgFactory = i1.ɵccf("app-custom-employee-overview", i10.EmployeeOverviewComponent, View_EmployeeOverviewComponent_Host_0, { masterElementContext: "masterElementContext", moduleElement: "moduleElement", element: "element", parentComponent: "parentComponent", isDialog: "isDialog", isPart: "isPart", toolbarItems: "toolbarItems", statusBarItems: "statusBarItems", selectedMasterEntity: "selectedMasterEntity", additional: "additional" }, { componentInstantiated: "componentInstantiated" }, []);
export { EmployeeOverviewComponentNgFactory as EmployeeOverviewComponentNgFactory };
