import { of as observableOf, forkJoin as observableForkJoin, of } from 'rxjs';
import { map, catchError, takeUntil } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash';
import { GenericTurboGridComponent } from '../../generic-turbo-grid/generic-turbo-grid.component';
import { GenericGridBulkSaveService } from '../../generic-grid/services/generic-grid-bulk-save.service';
import { Guid } from 'guid-typescript';
import { EntityStatus } from '../../../../services/entity/entity-status';
import { Entity } from '../../../../helpers/entity';
import { NumberHelper } from '../../../../helpers/number.helper';
import { InvoiceArticleAutocompleteHandlerService } from './autocomplete-handler/invoice-article-autocomplete-handler.service';
import { ChangeDetectorRefHelper } from '../../../../helpers/change-detector-ref.helper';
import { CostCenterAutocompleteHandlerService } from './autocomplete-handler/cost-center-autocomplete-handler.service';
import { SubAssignmentAutocompleteHandlerService } from './autocomplete-handler/sub-assignment-autocomplete-handler.service';
import { AssignmentAutocompleteHandlerService } from './autocomplete-handler/assignment-autocomplete-handler.service';
export class CustomerInvoicePositionDetailTableComponent extends GenericTurboGridComponent {
    constructor() {
        super(...arguments);
        this.toolbarItems = [];
        this.statusBarItems = [];
        this.masterEntity = null;
        this.masterField = null;
        this.isPart = false;
        this.entity = null;
        this.onExpanderChange = new EventEmitter();
        this.enableKeyboardAdd = false;
        this.columns = [];
        this.rows = [];
        this.entities = [];
        this.totalCount = 0;
        this.isLoadingData = false;
        this.toolbarContextName = 'customerInvoicePositionComponent';
    }
    set invoicePosition(invoicePosition) {
        this.invoicePositionEntity = invoicePosition;
        this.initColumns();
    }
    ;
    get invoicePosition() {
        return this.invoicePositionEntity;
    }
    ngOnInit() {
        super.ngOnInit();
        this.onComponentInit();
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.onDestroyComponent();
    }
    onComponentInit() {
        this.initColumns();
        this.layoutService.layoutSizeChanged$.subscribe(() => {
            setTimeout(() => {
                this.setTableScrollHeightAndWidth();
            }, 50);
        });
        this.embeddedFields.push('costCenter');
        this.embeddedFields.push('subAssignment');
        this.embeddedFields.push('assignment');
    }
    returnState() {
    }
    removeState() {
    }
    initColumns() {
        const invoiceHandler = new InvoiceArticleAutocompleteHandlerService(this.genericCrudService), costCenterHandler = new CostCenterAutocompleteHandlerService(this.genericCrudService), subAssignmentHandler = new SubAssignmentAutocompleteHandlerService(this.genericCrudService), assignmentHandler = new AssignmentAutocompleteHandlerService(this.genericCrudService), editable = typeof this.invoicePosition.isEditable === 'undefined' || this.invoicePosition.isEditable === true;
        this.columns = [
            {
                key: 'invoiceArticle',
                header: this.translationService.instant('INVOICE.INVOICE_ARTICLE'),
                renderer: (entity) => {
                    const invoiceArticle = Entity.getValue(entity, 'invoiceArticle') ||
                        Entity.getValueInEmbedded(entity, 'invoiceArticle');
                    if (invoiceArticle) {
                        return `${invoiceArticle.code} ${invoiceArticle.name}`;
                    }
                    return '';
                },
                edit: editable ? {
                    type: 'autocomplete',
                    autocompleteHandler: invoiceHandler
                } : undefined
            },
            {
                key: 'amount',
                header: this.translationService.instant('INVOICE.AMOUNT'),
                renderer: (entity) => {
                    const value = Entity.getValue(entity, 'amount');
                    return NumberHelper.formatNumber(value, ',', '.', 2);
                },
                edit: editable ? {
                    type: 'number-v2'
                } : undefined
            },
            {
                key: 'description',
                header: this.translationService.instant('INVOICE.DETAIL_DESCRIPTION'),
                edit: editable ? {} : undefined
            },
            {
                key: 'timeEntry',
                header: this.translationService.instant('INVOICE.TIME_ENTRY'),
                edit: editable ? {} : undefined
            },
            {
                key: 'value',
                header: this.translationService.instant('INVOICE.VALUE'),
                renderer: (entity) => {
                    const value = Entity.getValue(entity, 'value');
                    return NumberHelper.formatNumber(value, ',', '.', 2);
                },
                edit: editable ? {
                    type: 'number-v2'
                } : undefined
            },
            {
                key: 'total',
                header: this.translationService.instant('INVOICE.TOTAL'),
                renderer: (entity) => {
                    const value = Entity.getValue(entity, 'total');
                    return NumberHelper.formatNumber(value, ',', '.', 2);
                }
            },
            {
                key: 'costCenter',
                header: this.translationService.instant('INVOICE.COST_CENTER'),
                edit: editable ? {
                    type: 'autocomplete',
                    autocompleteHandler: costCenterHandler
                } : undefined,
                renderer: (entity) => {
                    return Entity.getValue(entity, 'costCenter.name');
                }
            },
            {
                key: 'subAssignment',
                header: this.translationService.instant('INVOICE.SUB_ASSIGNMENT'),
                edit: editable ? {
                    type: 'autocomplete',
                    autocompleteHandler: subAssignmentHandler
                } : undefined,
                renderer: (entity) => {
                    const subAssignment = Entity.getValue(entity, 'subAssignment') ||
                        Entity.getValueInEmbedded(entity, 'subAssignment');
                    if (!subAssignment) {
                        return '';
                    }
                    return Entity.getValue(subAssignment, 'subAssignmentNumber');
                }
            },
            {
                key: 'assignment',
                header: this.translationService.instant('INVOICE.ASSIGNMENT'),
                edit: editable ? {
                    type: 'autocomplete',
                    autocompleteHandler: assignmentHandler
                } : undefined,
                renderer: (entity) => {
                    const assignment = Entity.getValue(entity, 'assignment') ||
                        Entity.getValueInEmbedded(entity, 'assignment');
                    if (!assignment) {
                        return '';
                    }
                    return Entity.getValue(assignment, 'assignmentNumber') + ' ' + Entity.getValue(assignment, 'name');
                }
            },
            {
                key: 'menu',
                header: '',
                style: {
                    width: '30px'
                },
                menu: editable ? {
                    buttons: [
                        { click: this.onDelete.bind(this), icon: 'fa fa-trash' }
                    ]
                } : undefined
            }
        ];
        return this;
    }
    onCellEdit(event) {
        const column = event.column, entity = event.entity, originalEvent = event.originalEvent;
        let entityValue = originalEvent.target ? originalEvent.target.value : originalEvent.value;
        if (column.edit && column.edit.type === 'autocomplete') {
            entityValue = originalEvent;
        }
        this.entityManager.persist(entity, { property: EntityStatus.ENTITY_CHANGED_FLAG, newValue: true, force: true });
        this.entityManager.persist(entity, { property: column.key, newValue: entityValue, force: true });
        if (entityValue && (column.key === 'value' || column.key === 'amount')) {
            const value = Entity.getValue(entity, 'value'), amount = Entity.getValue(entity, 'amount');
            this.entityManager.persist(entity, { property: 'total', newValue: value * amount, force: true });
        }
        this.onExpanderChange.emit(entity);
    }
    startNewEntityAdd(entity) {
        entity = entity || this.emptyEntity;
        entity.invoicePosition = this.invoicePosition;
        entity.fqn = 'PhoenixBundle\\Entity\\InvoicePositionDetail';
        this.finishNewEntityAdd(entity);
    }
    finishNewEntityAdd(entity) {
        const newEntity = cloneDeep(entity);
        newEntity[EntityStatus.ENTITY_DRAFT_FLAG] = Guid.create().toString();
        this.entityManager.persist(newEntity, { property: 'invoicePosition', newValue: this.invoicePosition, force: true });
        this.entityManager.persist(newEntity, { property: 'fqn', newValue: 'PhoenixBundle\\Entity\\InvoicePositionDetail', force: true });
        this.entities = [...this.entities, newEntity];
        this.selectedEntity = newEntity;
        this.updateRowsStyle();
        this.onExpanderChange.emit(entity);
    }
    onDestroyComponent() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    getSelectedEntity() {
        return this.selectedMasterEntity || null;
    }
    recheckToolbarItems() {
        this.toolbarItemCheckService.check(this);
    }
    onSave() {
        const observables = [];
        const createdEntities = this.getCreatedEntities(false);
        for (const entity of createdEntities) {
            this.entityManager.persist(entity, { property: 'invoicePosition', newValue: this.invoicePosition, force: true });
            if (!entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG]) {
                observables.push(this.genericCrudService.createEntity(`phoenix/invoicepositiondetails`, entity, false, {
                    embedded: 'none'
                }));
            }
        }
        const updatedEntities = this.getUpdatedEntities(false);
        for (const entity of updatedEntities) {
            this.entityManager.persist(entity, { property: 'invoicePosition', newValue: this.invoicePosition, force: true });
            if (!entity[EntityStatus.ENTITY_DRAFT_DELETED_FLAG]) {
                observables.push(this.genericCrudService.editEntity(`phoenix/invoicepositiondetails/${entity.id}`, entity, {
                    embedded: 'none'
                }));
            }
        }
        const deletedEntitiesDraft = this.getDraftDeletedEntities();
        for (const entity of deletedEntitiesDraft) {
            this.entityManager.persist(entity, { property: 'invoicePosition', newValue: this.invoicePosition, force: true });
            observables.push(this.genericCrudService.deleteEntity(`phoenix/invoicepositiondetails/${entity.id}`));
        }
        if (observables.length === 0) {
            return of({
                status: true,
                content: [],
                message: GenericGridBulkSaveService.SAVE_MESSAGE_NOTHING_TO_SAVE
            });
        }
        return observableForkJoin(observables).pipe(catchError((response) => {
            return observableOf(response);
        }))
            .pipe(map((results) => {
            return {
                status: true,
                content: results
            };
        }), takeUntil(this.unsubscribe));
    }
    onAfterSave() {
        return observableOf(null);
    }
    onChange() {
        return observableOf(null);
    }
    doValidate() {
        return observableOf({
            entity: null,
            isValid: true,
            error: '',
            errorFields: []
        });
    }
    onLazyLoad(event) {
        this.currentOffset = event.first;
        this.defaultPageSize = event.rows;
        this.sortField = event.sortField || 'id';
        this.sortDirection = event.sortOrder === 1 ? 'asc' : 'desc';
        if (this.invoicePosition && this.invoicePosition.id) {
            this.loadEntities().subscribe();
        }
    }
    setTableScrollHeightAndWidth() {
    }
    loadEntities() {
        this.isLoadingData = true;
        const params = {
            offset: this.currentOffset,
            limit: this.defaultPageSize,
            orderBy: this.sortField,
            orderDirection: this.sortDirection
        };
        const route = `phoenix/invoicepositiondetails/offset/${params.offset}/limit/${params.limit}/orderby/${params.orderBy}/${params.orderDirection}?embedded=invoiceArticle,assignment,subAssignment`;
        return this.genericCrudService
            .getEntities(route, '', {
            'invoicePosition': this.invoicePosition.id
        }).pipe(map((paginated) => {
            this.entities = paginated.data;
            this.totalCount = paginated.total;
            for (const entity of this.entities) {
                entity[EntityStatus.ENTITY_CHANGED_FLAG] = false;
            }
            this.updateRowsStyle();
            this.setTableScrollHeightAndWidth();
            this.isLoadingData = false;
            ChangeDetectorRefHelper.detectChanges(this);
        }));
    }
    onDelete(entity) {
        this.entityManager.persist(entity, { property: EntityStatus.ENTITY_DRAFT_DELETED_FLAG, newValue: true, force: true });
        this.onExpanderChange.emit(entity);
    }
    updateRowsStyle() {
        this.rows = [];
        for (const entity of this.entities) {
            this.rows.push({
                entity: entity,
                name: 'description',
                getClass: () => {
                    return this.getRowStyleClass(entity);
                }
            });
        }
    }
}
