<div class="ui-g">
  <div class="ui-g-2">
    <div class="ui-g-4">
      {{ 'COMMON.BRANCH_OFFICE' | translate }}:
    </div>

    <div class="ui-g-8">
      <app-generic-dropdown
        [api]="'intranet/activity/branch-offices'"
        (optionChanged)="onBranchOfficeChanged($event)"
        [labelKey]="'name'"
        [showEmptyOption]="true"
        [forceSelectionOnLoad] = "true"
        [selectedOption]="forcedBranchOffice"
      ></app-generic-dropdown>
    </div>
  </div>

  <div class="ui-g-2">
    <div class="ui-g-4">
      {{ 'COMMON.YEAR' | translate }}:
    </div>

    <div class="ui-g-8">
      <app-generic-dropdown
        [api]="'intranet/activity/years'"
        (optionChanged)="onYearChanged($event)"
        [forceSelectionOnLoad]="true"
        [selectedOption]="preselectedYear"
        [labelKey]="'name'"
        [showEmptyOption]="true"
      ></app-generic-dropdown>
    </div>
  </div>

  <div class="ui-g-2">
    <div class="ui-g-4">
      {{ 'COMMON.WEEK' | translate }}:
    </div>

    <div class="ui-g-8">
      <app-generic-dropdown
        [api]="'intranet/activity/weeks'"
        (optionChanged)="onWeekChanged($event)"
        [forceSelectionOnLoad]="true"
        [selectedOption]="preselectedWeek"
        [labelKey]="'name'"
        [showEmptyOption]="true"
      ></app-generic-dropdown>
    </div>
  </div>
</div>

<div class="ui-g" *ngIf="activityEntities.length > 0 || statisticEntities.length > 0">
  <div class="ui-g-12" *ngIf="statisticEntities.length > 0">
    <h2>
      {{ 'USER_ACTIVITY_REPORT.STATS' | translate }}:
    </h2>
  </div>
  <div class="ui-g-12" *ngIf="statisticEntities.length > 0">
    <shared-dynamic-table
      [dataKey]="'title'"
      [columns]="statisticColumns"
      [parentComponent]="this"
      [cells]="cells"
      [lazy]="false"
      [rowsCount]="9999"
      [entities]="statisticEntities"
      [isLoadingData]="isLoading"
      [selectFirstEntityWhenNothingIsSelected]="moduleElement.selectFirst"
      [showFilters]="false"
      [paginator]="false"
    ></shared-dynamic-table>
  </div>

  <div class="ui-g-12" *ngIf="activityEntities.length > 0">
    <h2>
      {{ 'USER_ACTIVITY_REPORT.ACTIVITIES' | translate }}:
    </h2>
  </div>
  <div class="ui-g-12" *ngIf="activityEntities.length > 0">
    <shared-dynamic-table
      #table
      [dataKey]="'title'"
      [columns]="activityColumns"
      [parentComponent]="this"
      [cells]="cells"
      [lazy]="false"
      [rowsCount]="9999"
      [entities]="activityEntities"
      [isLoadingData]="isLoading"
      [selectFirstEntityWhenNothingIsSelected]="moduleElement.selectFirst"
      [showFilters]="false"
      [paginator]="false"
    ></shared-dynamic-table>
  </div>
</div>

<p-dialog *ngIf="isStatisticsDialogVisible && selectedStatisticEntity" appendTo="body" [responsive]="true"
          [(visible)]="isStatisticsDialogVisible && selectedStatisticEntity" showEffect="fade" header="Statistik erfassen"
          [modal]="true" [width]="400" [height]="400">
  <p-toolbar styleClass="no-round-corner">
    <div class="buttons-container">
      <div class="item-spacer"></div>
      <div class="item-right">
        <button
          type="button"
          pButton
          (click)="onSaveStatistics()"
          label="{{ 'COMMON.SAVE' | translate }}"
        ></button>
      </div>
    </div>
  </p-toolbar>
  <p-fieldset legend="Statistik Daten">
    <div class="ui-grid ui-grid-responsive ui-fluid">
      <div class="ui-grid-row">
        <div class="ui-grid-col-12">
          <span></span>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-grid-col-4">
          <label for="monthlyPlan">
            Std. Plan Monat: *
          </label>
        </div>
        <div class="ui-grid-col-8">
          <input pInputText type="text" id="monthlyPlan" [(ngModel)]="selectedStatisticEntity.hours_planned_month"/>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-grid-col-4">
          <label for="monthlyReal">
            Std. ist lfd:
          </label>
        </div>
        <div class="ui-grid-col-8">
          <input pInputText type="text" id="monthlyReal" [(ngModel)]="selectedStatisticEntity.hours_real_month"/>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-grid-col-4">
          <label for="hours_planned_week">
            Plan Ma.:
          </label>
        </div>
        <div class="ui-grid-col-8">
          <input pInputText type="text" id="hours_planned_week" [(ngModel)]="selectedStatisticEntity.hours_planned_week"/>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-grid-col-4">
          <label for="hours_real_week">
            Ist Ma. KW{{selectedWeek}}:
          </label>
        </div>
        <div class="ui-grid-col-8">
          <input pInputText type="text" id="hours_real_week" [(ngModel)]="selectedStatisticEntity.hours_real_week"/>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-grid-col-4">
          <label for="target_percentage">
            Ziel(%):
          </label>
        </div>
        <div class="ui-grid-col-8">
          <input pInputText type="text" id="target_percentage" [(ngModel)]="selectedStatisticEntity.target_percentage"/>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-grid-col-4">
          <label for="hours_employees_sick">
            Krank Ma.:
          </label>
        </div>
        <div class="ui-grid-col-8">
          <input pInputText type="text" id="hours_employees_sick" [(ngModel)]="selectedStatisticEntity.hours_employees_sick"/>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-grid-col-4">
          <label for="hours_employees_holiday">
            Urlaub Ma.:
          </label>
        </div>
        <div class="ui-grid-col-8">
          <input pInputText type="text" id="hours_employees_holiday" [(ngModel)]="selectedStatisticEntity.hours_employees_holiday"/>
        </div>
      </div>
      <div class="ui-grid-row">
        <div class="ui-grid-col-4">
          <label for="hours_employees_no_assignment">
            ohne Auftrag:
          </label>
        </div>
        <div class="ui-grid-col-8">
          <input pInputText type="text" id="hours_employees_no_assignment" [(ngModel)]="selectedStatisticEntity.hours_employees_no_assignment"/>
        </div>
      </div>
    </div>
  </p-fieldset>
</p-dialog>

<p-dialog *ngIf="isActivityDialogVisible && selectedActivityEntity && form" appendTo="body" [responsive]="true"
          [(visible)]="isActivityDialogVisible && selectedActivityEntity" showEffect="fade" header="Statistik erfassen"
          [modal]="true" [width]="750" [height]="800">
  <p-toolbar styleClass="no-round-corner">
    <div class="buttons-container">
      <div class="item-spacer"></div>
      <div class="item-right">
        <button
          type="button"
          pButton
          (click)="onSaveActivity()"
          label="{{ 'COMMON.SAVE' | translate }}"
        ></button>
      </div>
      <div class="item-spacer"></div>
      <div class="item-right">
        <button
          type="button"
          pButton
          (click)="onSaveAndCloseActivity()"
          label="{{ 'COMMON.SAVE_CLOSE' | translate }}"
        ></button>
      </div>
    </div>
  </p-toolbar>
  <p-fieldset [legend]="selectedActivityEntity.user">
    <form *ngIf="form" [formGroup]="form">
      <p-fieldset legend="Kunde">
        <div class="ui-grid ui-grid-responsive ui-fluid">

            <div class="ui-grid-row">
              <div class="ui-grid-col-4">
                <h3>Aktivität</h3>
              </div>
              <div class="ui-grid-col-4">
                <h3>Plan</h3>
              </div>
              <div class="ui-grid-col-4">
                <h3>Ist</h3>
              </div>
            </div>
            <div class="ui-grid-row" *ngFor="let field of preparedCustomerFields">
              <div class="ui-grid-col-4">
                <label>
                  {{ field.label }}
                </label>
              </div>
              <div class="ui-grid-col-4">
                <input pInputText type="text" [formControlName]="field.fieldPlan" [readOnly]="!field.editablePlan" />
              </div>
              <div class="ui-grid-col-4 pl-1">
                <input pInputText type="text" [formControlName]="field.fieldReal" [readOnly]="!field.editableReal" />
              </div>
            </div>
        </div>
      </p-fieldset>
      <p-fieldset legend="Mitarbeiter">
        <div class="ui-grid ui-grid-responsive ui-fluid">

          <div class="ui-grid-row">
            <div class="ui-grid-col-4">
              <h3>Aktivität</h3>
            </div>
            <div class="ui-grid-col-4">
              <h3>Plan</h3>
            </div>
            <div class="ui-grid-col-4">
              <h3>Ist</h3>
            </div>
          </div>
          <div class="ui-grid-row" *ngFor="let field of preparedEmployeeFields">
            <div class="ui-grid-col-4">
              <label>
                {{ field.label }}
              </label>
            </div>
            <div class="ui-grid-col-4">
              <input pInputText type="text" [formControlName]="field.fieldPlan" [readOnly]="!field.editablePlan" />
            </div>
            <div class="ui-grid-col-4 pl-1">
              <input pInputText type="text" [formControlName]="field.fieldReal" [readOnly]="!field.editableReal" />
            </div>
          </div>
        </div>
      </p-fieldset>
    </form>
  </p-fieldset>
</p-dialog>
