import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {ContractOfEmploymentExitInformationService} from '../../../../content-renderer/elements/generic-toolbar/items/components/leased-employee/service/contract-of-employment-exit-information.service';
import {FormActionsEvents} from '../../../models/form';
import {
  ContractOfEmploymentExitAgreementDateService
} from '../../../../content-renderer/elements/generic-toolbar/items/components/leased-employee/service/contract-of-employment-exit-agreement-date.service';
import {FormViewerComponent} from '../../../form-viewer.component';
import {ContractOfEmploymentContactInformationService} from '../../../../content-renderer/elements/generic-toolbar/items/components/leased-employee/service/contract-of-employment-contact-information.service';
import {ContractOfEmploymentGradeNotesService} from '../../../../content-renderer/elements/generic-toolbar/items/components/leased-employee/service/contract-of-employment-grade-notes.service';
import {ContractOfEmploymentValidFromService} from '../../../../content-renderer/elements/generic-toolbar/items/components/leased-employee/service/contract-of-employment-valid-from.service';
import {TranslateService} from '@ngx-translate/core';
import {RuntimeFlagName} from '../../../../content-renderer/services/ElementContext';

export class HandleContractOfEmploymentFormActionHandler extends AbstractFormActionHandler {

  public constructor(
    private translate: TranslateService
  ) {
    super();
  }

  public handleAction(): void {
    const action = this.getAction(),
      component = this.getComponent();

    if (action && action.command === FormActionsEvents.ON_ENTITY_CHANGED) {
      this.doShowOrHideExitInformation(component);
      this.doShowOrHideExitAgreementDate(component);
      this.doSetGradeNotesRequired(component);
      this.doValidateValidFrom(component);

      const context = component.getElementContext();

      context.addRuntimeFlag({
        name: RuntimeFlagName.DisableDialogPersistHideAfterSave,
        status: null,
        active: true
      });
    }

    if (action && action.command === FormActionsEvents.ON_VALUE_CHANGED) {
      this.doShowOrHideExitAgreementDate(component);
      this.doSetGradeNotesRequired(component);
      this.doValidateValidFrom(component);

      if (component instanceof FormViewerComponent) {
        component.getValidator().clearCache();
        component.onValidate().subscribe();
      }
    }
  }

  private doShowOrHideExitInformation(component): void {
    const contractOfEmploymentVisibilityService = new ContractOfEmploymentExitInformationService();

    contractOfEmploymentVisibilityService.setForm(component)
      .setFormService(this.formService)
      .showOrHideExitInformation();
  }

  private doShowOrHideContactInformation(component): void {
    const contractOfEmploymentVisibilityService = new ContractOfEmploymentContactInformationService();

    contractOfEmploymentVisibilityService.setForm(component)
      .setFormService(this.formService)
      .showOrHideContactInformation();
  }

  private doShowOrHideExitAgreementDate(component): void {
    const contractOfEmploymentValidatorService = new ContractOfEmploymentExitAgreementDateService();

    contractOfEmploymentValidatorService.setForm(this.getComponent())
      .setFormService(this.formService)
      .setupExitAgreementDate();
  }

  private doSetGradeNotesRequired(component): void {
    const gradeNotesService = new ContractOfEmploymentGradeNotesService();

    gradeNotesService.setForm(component)
      .setupGradeNotes();
  }

  private doValidateValidFrom(component): void {
    const gradeNotesService = new ContractOfEmploymentValidFromService();

    gradeNotesService.setForm(component)
      .setTranslate(this.translate)
      .setupValidation();
  }
}
