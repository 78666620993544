import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {forkJoin, Subject} from 'rxjs';
import {GenericCrudService} from '../../../../../services/generic-crud.service';

interface SimpleTodo {
  id?: string;
  name: string;
  finishedAt?: Date|string;
  createdAt?: Date|string;
}

@Component({
  selector: 'app-custom-staff-cockpit-simple-todo',
  styleUrls: ['./staff-cockpit-simple-todo.component.scss'],
  templateUrl: './staff-cockpit-simple-todo.component.html',
})
export class StaffCockpitSimpleTodoComponent implements OnInit, OnDestroy {

  public isLoading = true;
  public unsubscribe = new Subject<void>();
  public todos: SimpleTodo[] = [];
  public openCount = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private genericCrudService: GenericCrudService
  ) {
  }

  ngOnInit(): void {
    const weekBefore = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);

    forkJoin([
      this.genericCrudService.getEntities('phoenix/simpletodos/offset/0/limit/100/orderby/finishedAt/asc', '', {
        finishedAt: 'greaterThan:' + weekBefore.toISOString()
      }).pipe(
        map(({ data }) => {
          return data
        })
      ),
      this.genericCrudService.getEntities('phoenix/simpletodos/offset/0/limit/100/orderby/createdAt/asc', '', {
        finishedAt: 'isNull:'
      }).pipe(
        map(({ data }) => {
          return data
        })
      )
    ]).pipe(
      takeUntil(this.unsubscribe),
    ).subscribe(([finishedTodos, nonFinishedTodos]) => {
      this.todos = nonFinishedTodos;
      for (const todo of finishedTodos) {
        const index = this.todos.findIndex((aTodo) => aTodo.id === todo.id);

        if (index === -1) {
          this.todos.push(todo);
        }
      }
      this.openCount = this.todos.filter((aTodo) => !aTodo.finishedAt).length;
      this.isLoading = false;
      this.cdr.detectChanges();
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onFinish(todo: SimpleTodo): void {
    todo.finishedAt = new Date();

    if (todo.id) {
      this.openCount--;
      this.genericCrudService.editEntity(`phoenix/simpletodos/${todo.id}`, {
        finishedAt: new Date()
      }).pipe(
        takeUntil(this.unsubscribe)
      ).subscribe();
    }
  }

  onSave(index: number, todo: SimpleTodo): void {
    this.isLoading = true;
    let observable = this.genericCrudService.editEntity(`phoenix/simpletodos/${todo.id}`, {
      name: todo.name
    })

    if (!todo.id) {
      observable = this.genericCrudService.createEntity(`phoenix/simpletodos`, {
        name: todo.name
      });
      this.openCount++;
    }

    observable.pipe(
      map((todo) => {
        this.todos[index] = todo;
        this.todos = [...this.todos];
        this.isLoading = false;
        this.cdr.detectChanges();
      }),
      takeUntil(this.unsubscribe)
    ).subscribe();
  }

  onAdd(): void {
    this.todos = [...this.todos, {
      name: ''
    }]
  }
}

