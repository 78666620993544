import {throwError as observableThrowError, Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Component, OnInit, OnDestroy, Input, ChangeDetectorRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessageGrowlService} from '../../../core/message/message-growl.service';
import {GenericCrudService} from '../../services/generic-crud.service';
import {CompanyCommunicationService} from '../services/company-communication.service';
import {ApiBuilderService} from '../../services/api.builder.service';
import {HttpErrorResponse} from '@angular/common/http';
import {RequestCachingService} from '../../services/request-caching.service';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';
import {EntitySharingService} from '../../content-renderer/services/entity-sharing.service';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';
import {ExecutorActionEvent} from '../../../core/executor/service/executor-actions/executor-action-event';
import {Guid} from 'guid-typescript';

@Component({
  selector: 'company-search-grid',
  templateUrl: './company-search-grid.component.html',
  styles: [
    `
      ::ng-deep .ui-fluid .ui-dropdown {
        width: 20px;
      }
    `
  ]
})
export class CompanySearchGrid implements OnInit, OnDestroy {

  public static readonly API_ROUTE = 'phoenix/customers';

  @Input() gridComponent: AbstractGenericGridComponent = null;
  @Input() customerImportEndpoint = CompanySearchGrid.API_ROUTE;

  public totalRecords = 0;

  public companyName = '';
  public companyCity = '';
  public region = '';
  public companyUid = '';
  public companySearchCheckboxes = 'companyRegister';
  public company: any;
  public importDisabled: boolean = false;

  public companies: any[] = [];

  public isDataLoading = false;

  public regions = [
    {
      label: 'Burgendland',
      value: 'b'
    },
    {
      label: 'Kärnten',
      value: 'k'
    },
    {
      label: 'Niederösterreich',
      value: 'n'
    },
    {
      label: 'Oberösterreich',
      value: 'o'
    },
    {
      label: 'Steiermark',
      value: 'st'
    },
    {
      label: 'Salzburg',
      value: 'sa'
    },
    {
      label: 'Tirol',
      value: 't'
    },
    {
      label: 'Vorarlberg',
      value: 'v'
    },
    {
      label: 'Wien',
      value: 'w'
    }
  ];

  protected token: string;

  constructor(
    protected translate: TranslateService,
    protected messageGrowlService: MessageGrowlService,
    protected genericCrudService: GenericCrudService,
    protected entitySharingService: EntitySharingService,
    protected companyCommunicationService: CompanyCommunicationService,
    protected apiBuilderService: ApiBuilderService,
    protected requestCachingService: RequestCachingService,
    protected authenticationService: AuthenticationService,
    public cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.token = Guid.create().toString();
  }

  ngOnDestroy() {
  }

  public onSearch() {
    if (this.companySearchCheckboxes.length === 0) {
      return this.messageGrowlService.error('Error', 'Please select at least one search criteria.');
    }

    const importUrl = this.getSearchUrl();

    this.isDataLoading = true;

    // First empty the previous searches
    this.requestCachingService.removeByExpression('compass/search');
    this.requestCachingService.removeByExpression('compass/import');

    this.genericCrudService.getEntities(importUrl).pipe(
      catchError((response: HttpErrorResponse) => {
        this.companies = [];
        this.totalRecords = 0;
        this.isDataLoading = false;
        this.cdr.detectChanges();
        return observableThrowError(response);
      }))
      .subscribe((entities) => {
        this.loadSearchResults({
          first: 0,
          rows: 10
        });
      });
  }

  public loadSearchResults(event): void {
    const order = (event.sortOrder === -1) ? 'desc' : 'asc';
    const field = (event.sortField === undefined) ? 'externalId' : event.sortField;

    const searchParams = {
      apiRoute: 'compassresults',
      offset: event.first,
      limit: event.rows,
      orderBy: field,
      orderDirection: order
    };

    const apiUrl = this.apiBuilderService.getPaginateApiRoute(searchParams);

    // First empty the previous searches
    this.requestCachingService.removeByExpression('compassresults');

    const apiParams = {
      sessionToken: this.token,
      checkIsImported: 1
    };
    if (this.companySearchCheckboxes.length !== 2) {
      if (this.companySearchCheckboxes === 'companyRegister') {
        apiParams['type'] = 1;
      }

      if (this.companySearchCheckboxes === 'commercialRegister') {
        apiParams['type'] = 3;
      }
    }

    this.genericCrudService.getPaginated(apiUrl, apiParams).subscribe((data) => {
      this.companies = data['data'];
      this.totalRecords = data['total'];

      this.isDataLoading = false;

      if (this.companies.length === 0) {
        this.messageGrowlService.info('Info', 'Nothing found.');
      }

      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  public onImportCompany(company) {
    const url = 'compass/search' + '/' + company['externalId'] + '/' + company['type'];
    if (!this.importDisabled) {
      this.importDisabled = true;
      this.entitySharingService.fetchEntities(url, {
        success: (parsedCompany) => {
          delete parsedCompany['id'];
          this.importDisabled = false;
          this.saveCompany(parsedCompany);
          ChangeDetectorRefHelper.detectChanges(this);
        }
      });
    }
  }

  private saveCompany(company): void {
    this.genericCrudService.createEntity(this.customerImportEndpoint + '/import', company).toPromise()
      .then((savedCompany) => {
        this.entitySharingService.clearCacheForRoute(this.customerImportEndpoint);

        this.companyCommunicationService.onCompanyAdded(company);

        this.messageGrowlService.success(this.translate.instant('COMMON.SUCCESS'), 'Imported successfully.');

        this.gridComponent.setSelectedEntity(savedCompany);
        this.gridComponent.executeAction(ExecutorActionEvent.EntityImported, this.gridComponent).subscribe();

        ChangeDetectorRefHelper.detectChanges(this);
      }).catch((error) => {
      this.messageGrowlService.error(error);
    });
  }

  private getSearchUrl(): string {
    let searchUrl = '';

    if (this.companySearchCheckboxes === 'companyRegister') {
      searchUrl = 'compass/import/companyregister/';
    }

    if (this.companySearchCheckboxes === 'commercialRegister') {
      searchUrl = 'compass/import/commercialregister/';
    }

    if (this.companySearchCheckboxes.length === 2) {
      searchUrl = 'compass/import/';
    }

    searchUrl += this.companyName + '?city=' + this.companyCity + '&token='
      + this.token + '&uid=' + this.companyUid + '&region=' + this.region;

    return searchUrl;
  }
}
