import { GenericElementAbstract } from '../../generic-element-abstract.component';
import { of as observableOf } from 'rxjs';
export class EmployeeOverviewComponent extends GenericElementAbstract {
    constructor() {
        super(...arguments);
        this.toolbarContextName = 'EmployeeOverviewComponent';
        this.entities = {};
    }
    ngOnInit() {
        super.ngOnInit();
        this.genericCrudService.getEntities(this.element.datamodel.apiRoute + '/grouped/user').subscribe((response) => {
            this.entities = response.data;
            this.cdr.detectChanges();
        });
        this.legendData = [
            { aku: 'BG A', aiigew: 'VW I', color: 'green' },
            { aku: 'BG B', aiigew: 'VW II', color: 'yellow' },
            { aku: 'BG C', aiigew: 'VW III', color: 'blue' },
            { aku: 'BG D', aiigew: 'VW IV', color: 'plum' },
            { aku: 'BG E', aiigew: 'VW V', color: 'orange' },
            { aku: 'Anfrage', aiigew: 'VW VI', color: 'lightgray' },
            { aku: '', aiigew: '', color: '' },
            { aku: 'Urlaub', aiigew: '', color: 'salmon' },
            { aku: 'krank', aiigew: '', color: 'red' }
        ];
    }
    onComponentInit() {
    }
    onDestroyComponent() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
    getSelectedEntity() {
        return this.selectedMasterEntity || null;
    }
    onSave() {
        return observableOf(null);
    }
    recheckToolbarItems() {
    }
    hasChanges(checkEmbedded = false) {
        return false;
    }
    onAfterSave() {
        return observableOf(null);
    }
    onChange() {
        return observableOf(null);
    }
    doValidate() {
        return observableOf({
            entity: null,
            isValid: true,
            error: '',
            errorFields: []
        });
    }
    onRefresh() {
        return observableOf(null);
    }
    getToolbarItemsExtraParams() {
        return {
            'EmployeeOverviewComponent': this
        };
    }
    colspanLength(user) {
        return Object.keys(user.value.customers).length;
    }
}
