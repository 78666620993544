import {Component, ElementRef} from '@angular/core';
import {AbstractGridColumnComponent} from '../abstract-grid-column.component';
import {Observable} from 'rxjs';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {RequestCachingService} from '../../../../../services/request-caching.service';
import {environment} from '../../../../../../../environments';
import {Entity} from '../../../../../helpers/entity';

@Component({
  selector: 'app-absence-action-column',
  templateUrl: `absence-action-column.component.html`,
})
export class AbsenceActionColumnComponent extends AbstractGridColumnComponent {

  private baseUrl = '';

  public constructor(
    protected elementRef: ElementRef,
    protected genericCrudService: GenericCrudService,
    protected requestCachingService: RequestCachingService
  ) {
    super(elementRef);
    this.baseUrl = environment.baseUrl;
  }

  public print(key: string): void {
    const me = this;
    this.doExport(key).subscribe((fileResponse) => {
      me.requestCachingService.removeByExpression('phoenix');

      this.downloadFile(fileResponse);
    });
  }

  private doExport(key: string): Observable<any> {
    if (this.entity && this.entity.id) {
      const datamodelId = this.component.getElementDatamodel().id;
      const url = `app/export/entity/${datamodelId}/file/${this.entity.id}/template/0/${key}`;

      return this.genericCrudService.customPost(url, {});
    }
  }

  private downloadFile(fileResponse) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.setProperty('display', 'none');
    a.href = this.baseUrl + '/' + fileResponse.file;
    a.target = '_blank';
    a.download = fileResponse.fileName;
    a.click();
  }

  public isHidden(key: string): boolean {
    let printTemplate = null;

    if (this.entity.fqn === 'PhoenixBundle\\Entity\\LeasedEmployeeAbsenceGeneral') {
      printTemplate = Entity.getValueInEmbedded(this.entity, 'absenceTypeGeneral.printTemplate');
    }
    if (this.entity.fqn === 'PhoenixBundle\\Entity\\LeasedEmployeeHoliday') {
      printTemplate = Entity.getValueInEmbedded(this.entity, 'absenceTypeHoliday.printTemplate');
    }
    if (this.entity.fqn === 'PhoenixBundle\\Entity\\LeasedEmployeeAbsenceTimeCompensation') {
      printTemplate = Entity.getValueInEmbedded(this.entity, 'absenceTypeTimeCompensation.printTemplate');
    }
    if (this.entity.fqn === 'PhoenixBundle\\Entity\\JournalEntry') {
      printTemplate = Entity.getValueInEmbedded(this.entity, 'journalEntryType.printTemplate');
    }

    let visible = false;
    if (key === 'pdf') {
      visible = this.getConfigValue('isPdfVisible');
      visible = visible && printTemplate && printTemplate.isPdfOnly;
    }
    if (key === 'docx') {
      visible = this.getConfigValue('isDocxVisible');
      visible = visible && printTemplate && printTemplate.isWordOnly;
    }

    return !visible;
  }
}
