import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';

export class SetupWorkHourPeriodGridExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue();

    if (component instanceof AbstractGenericGridComponent) {
      return this.doSetup(component);
    }

    return this.getFailObservable('You need to pass AbstractGenericGridComponent or FormViewerComponent as Payload value!');
  }

  protected doSetup(component: AbstractGenericGridComponent) {
    component.embeddedFields = [...component.embeddedFields, ['assignment,subAssignment,period,leasedEmployee']];

    return observableOf({status: true, content: []});
  }
}
