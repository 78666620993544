<div class="container">
  <div class="ui-g-12">
    <div class="ui-g-11"><strong>Aufgaben über Powertool (via Workflows vom angemeldeten User)</strong></div>
    <div class="ui-g-1"></div>
  </div>
  <div class="ui-g-12">
    <div class="ui-g-2"></div>
    <div class="ui-g-4">offene Kundenaufgaben</div>
    <div class="ui-g-2"><strong><a (click)="onOpenCustomerOpenTodos($event)" href="">{{ customerOpenTodoCount }}</a></strong></div>
  </div>
  <div class="ui-g-12">
    <div class="ui-g-2"></div>
    <div class="ui-g-4">offene Mitarbeiteraufgaben</div>
    <div class="ui-g-2"><strong><a (click)="onOpenLeasedEmployeeOpenTodos($event)" href="">{{ leasedEmployeeOpenTodoCount }}</a></strong></div>
  </div>
</div>
