/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./staff-cockpit-simple-todo.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../generic-loading/generic-loading.component.ngfactory";
import * as i3 from "../../../generic-loading/generic-loading.component";
import * as i4 from "../../../../../../../../node_modules/primeng/components/checkbox/checkbox.ngfactory";
import * as i5 from "primeng/components/checkbox/checkbox";
import * as i6 from "@angular/forms";
import * as i7 from "primeng/components/inputtext/inputtext";
import * as i8 from "@angular/common";
import * as i9 from "primeng/components/dom/domhandler";
import * as i10 from "primeng/components/button/button";
import * as i11 from "@ngx-translate/core";
import * as i12 from "../../../../../p-button/p-button.directive";
import * as i13 from "./staff-cockpit-simple-todo.component";
import * as i14 from "../../../../../services/generic-crud.service";
var styles_StaffCockpitSimpleTodoComponent = [i0.styles];
var RenderType_StaffCockpitSimpleTodoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StaffCockpitSimpleTodoComponent, data: {} });
export { RenderType_StaffCockpitSimpleTodoComponent as RenderType_StaffCockpitSimpleTodoComponent };
function View_StaffCockpitSimpleTodoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "ui-g-12 loading"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-generic-loading", [], null, null, null, i2.View_GenericLoadingComponent_0, i2.RenderType_GenericLoadingComponent)), i1.ɵdid(2, 114688, null, 0, i3.GenericLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
function View_StaffCockpitSimpleTodoComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "p-checkbox", [["binary", "true"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = (!!(_v.parent.context.$implicit.finishedAt = $event) !== false);
        ad = (pd_0 && ad);
    } if (("onChange" === en)) {
        var pd_1 = (_co.onFinish(_v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_Checkbox_0, i4.RenderType_Checkbox)), i1.ɵdid(1, 49152, null, 0, i5.Checkbox, [i1.ChangeDetectorRef], { disabled: [0, "disabled"], binary: [1, "binary"] }, { onChange: "onChange" }), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.Checkbox]), i1.ɵdid(3, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { isDisabled: [0, "isDisabled"], model: [1, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(5, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null)], function (_ck, _v) { var currVal_7 = !!_v.parent.context.$implicit.finishedAt; var currVal_8 = "true"; _ck(_v, 1, 0, currVal_7, currVal_8); var currVal_9 = !!_v.parent.context.$implicit.finishedAt; var currVal_10 = !!_v.parent.context.$implicit.finishedAt; _ck(_v, 3, 0, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_StaffCockpitSimpleTodoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "ui-g-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "input", [["pInputText", ""]], [[8, "placeholder", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null], [2, "ui-inputtext", null], [2, "ui-corner-all", null], [2, "ui-state-default", null], [2, "ui-widget", null], [2, "ui-state-filled", null]], [[null, "ngModelChange"], [null, "change"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("input" === en)) {
        var pd_4 = (i1.ɵnov(_v, 9).onInput($event) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_v.context.$implicit.name = $event) !== false);
        ad = (pd_5 && ad);
    } if (("change" === en)) {
        var pd_6 = (_co.onSave(_v.context.index, _v.context.$implicit) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 16384, null, 0, i6.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i6.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.DefaultValueAccessor]), i1.ɵdid(6, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i1.ɵdid(8, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), i1.ɵdid(9, 278528, null, 0, i7.InputText, [i1.ElementRef, [2, i6.NgModel]], null, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaffCockpitSimpleTodoComponent_4)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_13 = _v.context.$implicit.name; _ck(_v, 6, 0, currVal_13); _ck(_v, 9, 0); var currVal_14 = _v.context.$implicit.id; _ck(_v, 12, 0, currVal_14); }, function (_ck, _v) { var currVal_0 = "Name"; var currVal_1 = i1.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 8).ngClassValid; var currVal_6 = i1.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 8).ngClassPending; var currVal_8 = true; var currVal_9 = true; var currVal_10 = true; var currVal_11 = true; var currVal_12 = i1.ɵnov(_v, 9).filled; _ck(_v, 3, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12]); }); }
function View_StaffCockpitSimpleTodoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 23, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "ui-g-11"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Aufgabenplanung"])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "ui-g-1 open"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Offen: "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 6, "div", [["class", "ui-g-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "ui-g-10"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Todo"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "ui-g-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Fertig"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaffCockpitSimpleTodoComponent_3)), i1.ɵdid(17, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(18, 0, null, null, 5, "button", [["pButton", ""], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onAdd() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(4608, null, i9.DomHandler, i9.DomHandler, []), i1.ɵdid(20, 4341760, null, 0, i10.ButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null), i1.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i1.ChangeDetectorRef]), i1.ɵdid(22, 4341760, null, 0, i12.PButtonDirective, [i1.ElementRef], { label: [0, "label"] }, null), i1.ɵpid(131072, i11.TranslatePipe, [i11.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.todos; _ck(_v, 17, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 20, 0, i1.ɵnov(_v, 21).transform("COMMON.ADD")), ""); _ck(_v, 20, 0, currVal_2); var currVal_3 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 22, 0, i1.ɵnov(_v, 23).transform("COMMON.ADD")), ""); _ck(_v, 22, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.openCount; _ck(_v, 8, 0, currVal_0); }); }
export function View_StaffCockpitSimpleTodoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaffCockpitSimpleTodoComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StaffCockpitSimpleTodoComponent_2)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.isLoading; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_StaffCockpitSimpleTodoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-custom-staff-cockpit-simple-todo", [], null, null, null, View_StaffCockpitSimpleTodoComponent_0, RenderType_StaffCockpitSimpleTodoComponent)), i1.ɵdid(1, 245760, null, 0, i13.StaffCockpitSimpleTodoComponent, [i1.ChangeDetectorRef, i14.GenericCrudService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StaffCockpitSimpleTodoComponentNgFactory = i1.ɵccf("app-custom-staff-cockpit-simple-todo", i13.StaffCockpitSimpleTodoComponent, View_StaffCockpitSimpleTodoComponent_Host_0, {}, {}, []);
export { StaffCockpitSimpleTodoComponentNgFactory as StaffCockpitSimpleTodoComponentNgFactory };
