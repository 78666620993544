
import {throwError as observableThrowError, Observable, Subject} from 'rxjs';

import {map, catchError, takeUntil} from 'rxjs/operators';
import {Component, Input, OnInit, OnDestroy, AfterViewInit, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef} from '@angular/core';


import { ElementInputComponent } from './element-input.component';
import { DatamodelCrudService } from '../../services/datamodel/datamodel.crud.service';
import { FormService } from '../form.service';
import { FormViewerService } from '../form-viewer.service';
import { GenericCrudService } from '../../services/generic-crud.service';
import { ElementInputDropdown } from '../models';
import { FormElementAction} from '../models/form';
import { TranslateService } from '@ngx-translate/core';
import { ElementsStackService } from '../../content-renderer/services/elements-stack.service';
import { ElementType } from 'app/shared/content-renderer/services/ElementContext';
import { ElementService } from 'app/form-editor/shared/services/element.service';
import { FormActionsService } from '../actions/services/form-actions.service';
import { ComponentSelectedOptionAware } from './abstract-element.component';
import { SelectItemExtended } from './element-input-autocomplete.component';
import {EntityDraftStoreService} from '../../content-renderer/services/entity-draft-store.service';
import {EntityStatus} from '../../services/entity/entity-status';
import {Entity} from '../../helpers/entity';
import {JobContext} from '../../../core/job-runner/context/job.context';
import {RunnableEventRegistry} from '../../../core/job-runner/type/runnable-event.registry';
import {Guid} from 'guid-typescript';
import {JobContainerService} from '../../../core/job-runner/job-container.service';
import {isEmpty} from 'lodash';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';

export interface SelectItemTmpIsDeleted {
  label: string;
  value: any;
  tmpIsDeleted: boolean;
  entity?: any;
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-form-element-input-dropdown',
  template: `
    <div [formGroup]="formGroup">
      <p-dropdown
        appDropdownLoadingIndicator
        appSeleniumDirective
        [element]="element"
        [formControlName]="formControlName"
        [options]="pulldownOptions"
        [autoWidth]="false"
        [placeholder]="getPlaceholder()"
        [disabled]="checkIfDisabled()"
        [readonly]="isReadOnly()"
        (onChange)="onActionSelect($event)"
        [ngClass]="{'readonly-style': isReadOnly()}"
        [styleClass]="!element.isValid ? 'is-not-valid' : ''"
        (onClick)="onDropdownClick()"
        [tabIndex]="element.tabIndex"
        appendTo="body"
        #inputElement
      >
        <ng-template let-item pTemplate="item">
          <span
            [id]="'FormElementInput-' + element.label + '-Value-' + item.label"
          >
            {{ item.label }}
          </span>
        </ng-template>
      </p-dropdown>
    </div>
  `,
  styles: [`
    :host {
      height: 100%;
    }

    p-dropdown.readonly-style ::ng-deep label {
      background-color: lightgrey;
      border-color: lightgrey;
      color: black;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
    p-dropdown,
    p-dropdown ::ng-deep .ui-dropdown {
      width: 100%;
    }
    p-dropdown ::ng-deep .is-not-valid {
      border: 1px solid red;
    }
  `],
  providers: [
    DatamodelCrudService
  ]
})
export class ElementInputDropdownComponent extends ElementInputComponent implements OnInit, OnDestroy, AfterViewInit, ComponentSelectedOptionAware {

  @Input() element: ElementInputDropdown;

  pulldownOptions: SelectItemTmpIsDeleted[] = [];

  public loadUnsubscribe = new Subject<void>();

  constructor(
    protected formService: FormService,
    public cdr: ChangeDetectorRef,
    protected formViewerService: FormViewerService,
    protected genericCrudService: GenericCrudService,
    protected elementService: ElementService,
    protected elementRef: ElementRef,
    protected datamodelCrudService: DatamodelCrudService,
    protected translateService: TranslateService,
    protected elementsStackService: ElementsStackService,
    protected formActionsService: FormActionsService,
    protected entityDraftService: EntityDraftStoreService,
    protected jobContainerService: JobContainerService
  ) {
    super(formService, cdr, formViewerService, translateService, datamodelCrudService);
  }

  onComponentInit() {
    this.runOnInitJobs().subscribe((status) => {
      this.setFormControlName();
      this.filterActionAndHandleIt('oninit');

      this.subscriptions.push(
        this.formService.elementChanged.subscribe((element: ElementInputDropdown) => {
          // GU :: this should be handled in more abstract way :)
          if (this.element === element) {
            this.onElementMetaChanged(element);
          }
        })
      );
    });
  }

  runOnInitJobs(): Observable<boolean> {
    const context = new JobContext();
    context.component = this;
    context.event = RunnableEventRegistry.PostInit;
    context.identifier = Guid.create().toString();

    return this.jobContainerService.runRelevantJobsObservable(context);
  }

  public onComponentChanges() {
    this.setupValue();
  }

  onComponentDestroy() {
    super.onComponentDestroy();
    this.loadUnsubscribe.next();
    this.loadUnsubscribe.complete();
  }

  ngAfterViewInit() {
    this.setupValue();
  }

  onFormElementAction(action: FormElementAction) {

  }

  private extendByDraftEntities(entityList: Array<any>) {
    const draftSaveEntities = this.entityDraftService.getEntities(this.buildFqn());

    for (const draftEntity of draftSaveEntities) {
      if (!this.inList(draftEntity, entityList)) {
        entityList.push(draftEntity);
      }
    }
  }

  private buildFqn() {
    if (!this.element || !this.element.datamodel) {
      return '';
    }

    const nameParts = this.element.datamodel.name.split('.');

    return `${nameParts[0]}\\Entity\\${nameParts[1]}`;
  }

  private inList(entity: any, entityList: Array<any>): boolean {
    for (const listEntity of entityList) {
      if (entity[EntityStatus.ENTITY_DRAFT_FLAG] === listEntity[EntityStatus.ENTITY_DRAFT_FLAG]) {
        return true;
      }
    }

    return false;
  }

  public setValue(selectedOption: SelectItemExtended, triggerChange: boolean = true, entityValueEmpty: boolean = false) {
    const isEntityValueString = typeof this.getEntityValue(false) === 'string';

    let entityValue: any = entityValueEmpty ? null : selectedOption;
    let formControlValue = selectedOption.value;

    if (selectedOption instanceof Object && selectedOption.value !== 'undefined' && selectedOption.value === null){
      entityValue = null;
    }

    if (selectedOption instanceof Object && typeof selectedOption.entity !== 'undefined' && selectedOption.entity !== null) {
      if (this.element.dropdownFieldValue) {
        entityValue = Entity.getValue(selectedOption.entity, this.element.dropdownFieldValue) ||
          Entity.getValueInEmbedded(selectedOption.entity, this.element.dropdownFieldValue);
        formControlValue = {};
        formControlValue[this.element.dropdownFieldValue] = entityValue;
      } else {
        entityValue = selectedOption.entity;
        formControlValue = {id: selectedOption.entity.id};
      }
    }

    if (selectedOption instanceof Object && selectedOption.value &&
      (typeof selectedOption.entity === 'undefined' || selectedOption.entity === null)
    ) {
      entityValue = selectedOption.value;
    }

    if (isEntityValueString && typeof selectedOption.value === 'string') {
      formControlValue = {
        id: selectedOption.value
      };
    }
    this.getSelectedOption(selectedOption);
    if (typeof (selectedOption) !== 'undefined') {
      this.formService.onFormElementValueChange({
        formControlValue: formControlValue,
        element: this.element,
        entityValue: entityValue,
        formControlName: this.formControlName,
        formControlOptions: {},
        triggerChange: triggerChange,
        entity: this.entity,
        component: this,
        updateFormComponent: true
      });
    }

    return this;
  }

  protected canSetupEntityValue(): boolean {
    let canSetupValue = false;

    const value = this.getEntityValue();

    if (typeof value === 'string' && value !== '') {
      canSetupValue = true;
    }

    if (value instanceof Object && Object.keys(value).length !== 0) {
      canSetupValue = true;
    }

    return canSetupValue;
  }

  public setupValue() {
    const value = this.getEntityValue(),
      canSetupEntityValue = this.canSetupEntityValue();

    if (canSetupEntityValue) {
      const entity = this.getEntityValue(false);

      if (entity && entity['id']) {
        const option = {
          label: this.figureOutLabel(value),
          value: { id: entity.id },
          tmpIsDeleted: value.tmpIsDeleted || false,
          entity: entity
        };

        this.addOption(option);

        this.setValue(option, false);
      }
    } else if (!canSetupEntityValue && this.element.defaultValue) {
      this.setDefaultValue();
    } else if (!canSetupEntityValue && !this.element.defaultValue && this.element.selectIfSingleValue) {
      this.setFirstValue();
    } else if (this.pulldownOptions.length === 1 && !this.element.isEmptyValueAllowed) {
      this.setFirstValue(true);
    } else {
      this.setValue({
        label: this.getPlaceholder(),
        value: null
      }, false, true);
    }
  }

  private handleIsTmpDeleted(item: SelectItemTmpIsDeleted): void {
    let isValid = true;

    if (item && item.tmpIsDeleted) {
      isValid = false;
    }

    this.setIsValid(isValid);
  }

  onActionSelect(event?: any) {
    this.selectedOption = null;

    if (event) {
      const selectedOption = this.getSelectedOption(event);

      this.element.dropdownFieldLabelValue = selectedOption.label;
    }

    if (this.selectedOption && this.selectedOption.entity && this.element.getEntity()._embedded) {
      this.element.getEntity()._embedded[this.element.datamodelField] = this.selectedOption.entity;
    }

    this.handleIsTmpDeleted(this.selectedOption);
    this.setValue(this.selectedOption);
  }

  public getSelectedOption(value: any) {
    if (!this.selectedOption || this.selectedOptionChanged(value) || (!this.selectedOption.entity && value.entity)) {
        this.selectedOption = this.pulldownOptions.find((option) => {
            if (option.entity && value.entity) {
                return option.entity.id === value.entity.id;
            } else if (option.value && option.value.id && value.value && value.value.id) {
                return option.value.id === value.value.id;
            } else {
              return option.value === value.value;
            }
        });
    }

    return this.selectedOption;
  }

  public onDropdownClick(): void {
    this.loadDropdownOptions();
  }

  public addOption(option: SelectItemTmpIsDeleted): this {
    const alreadyExists = this.optionExists(option);

    if (!alreadyExists) {
      this.pulldownOptions = [...this.pulldownOptions, option];
    }

    return this;
  }

  public optionExists(option: SelectItemTmpIsDeleted): boolean {
    let exists = false;

    for (const aOption of this.pulldownOptions) {
      if ((aOption.value === option.value) ||
        (option.value && option.value.id && aOption.value && aOption.value.id && option.value.id === aOption.value.id)) {
        exists = true;
        break;
      }
    }

    return exists;
  }

  protected loadDropdownOptions(): void {
    if (this.element['dropdownFieldLabel']) {
      let ddFieldLabels, ddFieldValues;
      try {
        ddFieldLabels = JSON.parse(this.element['dropdownFieldLabel']);
        ddFieldValues = JSON.parse(this.element['dropdownFieldValue']);
      } catch (error) {
        ddFieldLabels = undefined;
        ddFieldValues = undefined;
      }

      if (this.element.isEmptyValueAllowed) {
        this.pulldownOptions = [{
          label: this.getPlaceholder(),
          value: null,
          tmpIsDeleted: false,
          entity: null
        }];
      }

      if (ddFieldLabels && ddFieldValues) {
        this.initCustomOptions(ddFieldLabels, ddFieldValues);
      } else {
        this.loadDatamodelOptions().subscribe();
      }
    }
  }

  initCustomOptions(labels: string[], values: string[]) {
    labels.map((val, index) => {
      this.pulldownOptions.push({
        label: val,
        value: values[index],
        tmpIsDeleted: val['tmpIsDeleted']
      });
    });

    if (!this.element.placeholder && this.pulldownOptions.length > 0) {
      this.setValue(this.pulldownOptions[0].value, false)
        .handleIsTmpDeleted(this.pulldownOptions[0]);
    }
  }

  loadDatamodelOptions(opts = {setupValue: true}): Observable<SelectItemTmpIsDeleted[]> {
    const requestParams = {};

    this.filterActionAndHandleIt('onBeforeDatamodelLoad');

    if (this.element.datamodel) {
      let url = `${this.element.datamodel.apiRoute}/offset/0/limit/50`;

      if (this.element.firstSortingField && this.element.firstSortingDirectionField) {
        if (this.element.secondSortingField && this.element.secondSortingDirectionField) {
          url += `/orderby/${this.element.firstSortingField},${this.element.secondSortingField}/${this.element.firstSortingDirectionField},${this.element.secondSortingDirectionField}`;
        } else {
          url += `/orderby/${this.element.firstSortingField}/${this.element.firstSortingDirectionField}`;
        }
      }else{
        url += `/orderby/id/asc`;
      }

      url = this.element.customApiRoute ? this.element.customApiRoute : url;

      if (this.element.firstSortingTypeField) {
        if (this.element.secondSortingTypeField) {
          requestParams['orderType'] = `${this.element.firstSortingTypeField},${this.element.secondSortingTypeField}`;
        } else {
          requestParams['orderType'] = `${this.element.firstSortingTypeField}`;
        }
      }

      if (this.element.staticFilterField && this.element.staticFilterValue) {
        requestParams[this.element.staticFilterField] = `${this.element.staticFilterValue}`;
      }

      if (this.element.masterFilterField && null !== this.getFormContextMasterEntityValue()) {
        requestParams[this.element.masterFilterField] = `${this.getFormContextMasterEntityValue()}`;
      } else if (this.element.masterFilterField) {
        let value = {};
        if (this.element.masterFilterFieldValue){
          value = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, this.element.masterFilterFieldValue);
        }else {
          value = this.formService.getEntityHydrator().getEntityPropertyValue(this.entity, this.element.masterFilterField);
        }
        if (value && typeof value === 'object' && value['id']) {
          value = value['id'];
        }

        if (this.element.isManyInMasterFilterField){
          requestParams['tempEmbedded'] = this.element.masterFilterField;
          requestParams[this.element.masterFilterField] = 'manyIn:' + value;
        }else{
          requestParams[this.element.masterFilterField] = value;
        }
      }
      if (this.element.isEmbeddedNone){
        requestParams['embedded'] = 'none';
      }

      if (this.element.embeddedFields && this.element.embeddedFields !== '') {
        requestParams['embedded'] = this.element.embeddedFields;
      }

      if (this.element.datamodelFieldFilter && this.element.datamodelFieldFilterValue) {
        requestParams[this.element.datamodelFieldFilter] = this.element.datamodelFieldFilterValue;
      }

      return this.genericCrudService.getEntities(url, '', requestParams).pipe(
        takeUntil(this.loadUnsubscribe),
        catchError((response) => {
          return observableThrowError(response);
        }),
        map((dmRecords: any) => {
          this.pulldownOptions = [];

          if (this.element.isEmptyValueAllowed) {
            this.pulldownOptions = [{
              label: this.getPlaceholder(),
              value: null,
              tmpIsDeleted: false
            }];
          }

          const records = (dmRecords.data) ? dmRecords.data : dmRecords;

          this.extendByDraftEntities(records);

          records.map((record) => {
            const label: string = this.figureOutLabel(record);
            let value: any = undefined;

            if (this.element.dropdownFieldValue && record[this.element.dropdownFieldValue]) {
              value = { id: record[this.element.dropdownFieldValue] };
            } else {
              value = { id: record.id };
            }

            this.addOption({
              label: label,
              value: value,
              tmpIsDeleted: record['tmpIsDeleted'],
              entity: record
            });
          });

          if (opts.setupValue) {
            this.setupValue();
          }
          this.filterActionAndHandleIt('ondatamodelload');

          ChangeDetectorRefHelper.detectChanges(this);
          return this.pulldownOptions;
        }));
    }
  }

  protected setDefaultValue() {
    const defaultValue = +this.element.defaultValue,
      apiRoute = this.element.customApiRoute ? this.element.customApiRoute : this.element.datamodel.apiRoute;

    this.subscriptions.push(
      this.genericCrudService
        .getEntity(apiRoute, defaultValue).subscribe((defaultEntity: {
        id: number,
        tmpIsDeleted: boolean
      }) => {
        const entityValue = this.getEntityValue();

        // double check, as it is async
        if (!entityValue) {
          const option = {
            label: this.figureOutLabel(defaultEntity),
            value: {id: defaultEntity.id},
            tmpIsDeleted: defaultEntity.tmpIsDeleted || false,
            entity: defaultEntity
          };

          this.addOption(option);

          this.setValue({
            label: this.figureOutLabel(defaultEntity),
            value: defaultEntity,
            entity: defaultEntity
          }, false).handleIsTmpDeleted(option);

          if (this.selectedOption) {
            this.filterActionAndHandleIt('onselect');
          }

          ChangeDetectorRefHelper.detectChanges(this);
        }
      })
    );
  }

  protected setFirstValue(triggerChange = false) {
    this.loadUnsubscribe.next();
    this.loadUnsubscribe.complete();

    this.loadUnsubscribe = new Subject();

    this.loadDatamodelOptions({
      setupValue: false
    }).subscribe(() => {
      let lengthToCheck = 1;

      if (this.element.isEmptyValueAllowed && this.pulldownOptions.length === 2) {
        lengthToCheck = 2;
      }

      if (this.pulldownOptions.length === lengthToCheck) {
        this.setValue(this.pulldownOptions[lengthToCheck - 1], triggerChange)
          .handleIsTmpDeleted(this.pulldownOptions[lengthToCheck - 1]);

        if (this.selectedOption) {
          this.filterActionAndHandleIt('onselect');
        }
      }

      ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  protected getEntityValue(useEmbedded: boolean = true): any | null {
    let value = super.getEntityValue(useEmbedded);

    if (isEmpty(value)) {
      value = null;
    }

    return value;
  }

  checkIfDisabled() {
    let disabled = false;
    if (this.element.disabled) {
      disabled = true;
    }

    return disabled;
  }

  getPlaceholder() {
    let placeholder = undefined;

    if (this.element.placeholder) {
      placeholder = this.element.placeholder;
    }

    return placeholder;
  }

    public onElementMetaChanged(element: ElementInputDropdown) {
      this.onElementStaticFilterValueChange();
    }

    public onElementStaticFilterValueChange(): this {
      this.loadDatamodelOptions().subscribe();

      return this;
    }

    public focusInput() {
      this.elementRef.nativeElement.querySelector('.ui-dropdown-trigger').click();
    }

    private getFormContextMasterEntityValue(): any|null {
      let value = null,
        formContext = null;

      if (this.moduleElement && this.element.masterFilterField) {
          formContext = this.elementsStackService.findByIdAndType(this.moduleElement.id, ElementType.Form);
      }

      if (null !== formContext && formContext.masterElementContext) {
        const masterEntity = formContext.masterElementContext.findMasterEntity(this.element.masterFilterField);

        if (masterEntity) {
          value = masterEntity.value;
        }
      }

      return value;
    }
}
