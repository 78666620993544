
import {throwError as observableThrowError, Observable} from 'rxjs';

import {catchError} from 'rxjs/operators';
import { Component, OnInit, Input } from '@angular/core';
import {Menu} from '../../../../../../shared/services/menu/menu';
import {Router} from '@angular/router';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from "../../../../../service/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {GenericCrudService} from "../../../../../../shared/services/generic-crud.service";
import {MessageGrowlService} from "../../../../../message/message-growl.service";
import {HttpErrorResponseService} from "../../../../../../shared/services/http-error-response-message.service";
import {environment} from '../../../../../../../environments';
import {RequestCachingService} from '../../../../../../shared/services/request-caching.service';

@Component({
    selector: 'app-contract-of-employment-csv-export-menu-item',
    templateUrl: './contract-of-employment-csv-export-menu-item.component.html',
    styleUrls: ['./contract-of-employment-csv-export-menu-item.component.scss']
})
export class ContractOfEmploymentCsvExportMenuItemComponent implements OnInit {

  @Input() iconsPath = '';
  @Input() item: Menu;
  @Input() menuPosition = 'left';

  public isDialogVisible = false;

  public exportMode = 'all';
  public baseUrl = environment.baseUrl;

  constructor(
    protected toastService: ToastService,
    protected translateService: TranslateService,
    protected genericCrudService: GenericCrudService,
    protected messageGrowlService: MessageGrowlService,
    protected requestCachingService: RequestCachingService,
    protected httpErrorResponseService: HttpErrorResponseService,
  ) { }

  ngOnInit() {

  }

  public onItemClick(item) {
    this.isDialogVisible = true;

    return false;
  }

  onExport(event) {

    this.toastService.custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.translateService.instant('COMMON.LOADING_PLEASE_WAIT')
    });

    let url = 'phoenix/export/bmd/contractofemployments';

    if (this.exportMode === 'latest') {
      url += '/latest';
    }

    this.genericCrudService.get(url).pipe(
      catchError((response) => {
        const errors = this.httpErrorResponseService.getErrors(response);
        this.messageGrowlService.error(errors.toString());
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);

        return observableThrowError(response);
      }))
      .subscribe((fileName) => {
        this.toastService.clear(ToastComponentsRegistry.PROGRESS_BAR);
        this.requestCachingService.removeByExpression('phoenix');
        this.downloadFile(fileName);
      });
  }

  private downloadFile(fileName) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.setProperty('display', 'none');
    a.href = this.baseUrl + '/assets/exports/bmd' + '/' + fileName;
    a.download = fileName;
    a.click();
  }
}
