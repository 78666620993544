import {of as observableOf, Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericDialogModuleService} from '../../../content-renderer/elements/generic-dialog/service/generic-dialog-module.service';
import {AbstractGenericGridComponent} from '../../../content-renderer/elements/abstract-generic-grid.component';
import {MessageService} from '../../../../core/message/message.service';

export class CreateMemoFromAssignmentExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload();

    if (!(payload.getValue() instanceof AbstractGenericGridComponent)) {
      return this.getFailObservable('You need to pass AbstractGenericGridComponent as Payload value!');
    }

    return this.doSet(payload.getValue());
  }

  protected doSet(component: AbstractGenericGridComponent): Observable<ExecutionStepStatus> {
    this.injector.get(MessageService).confirm({
      acceptVisible: true,
      rejectVisible: true,
      header: 'Bestätigung',
      message: 'Sicher ???',
      accept: () => {
        const selectedEntities = {
          leasedEmployees: component.selectedEntities.map(function (item) {return item.leasedEmployee})
        }
        if (selectedEntities) {
          this.genericCrudService.customPost('phoenix/assignments/fromLeasedEmployee/memo', selectedEntities).subscribe(() => {

          });
        }
      },
      reject: () => {
        return null;
      }
    });
    return observableOf({status: true, content: null});
  }
}
