import {JobInterface} from '../../../core/job-runner/job/job.interface';
import {JobContext} from '../../../core/job-runner/context/job.context';
import {Observable, of} from 'rxjs';
import {JobExecutionStatus} from '../../../core/job-runner/job/job-execution-status';
import {ConditionInterface} from '../../../core/job-runner/condition/condition.interface';
import {AbstractJob} from '../../../core/job-runner/job/abstract.job';
import {take} from 'rxjs/operators';
import {AbstractGenericGridComponent} from '../../content-renderer/elements/abstract-generic-grid.component';

export class SetTodoOpenStateFilterJob extends AbstractJob implements JobInterface {

  run(context: JobContext, condition: ConditionInterface): Observable<JobExecutionStatus> {
    const component = context.component;
    const code = condition.getPayload().todoOwnerTypeCode;

    if (component instanceof AbstractGenericGridComponent) {
      component.embeddedFields = [...component.embeddedFields, 'todoOwnerType']
      component.staticFilters.push({
        field: 'todoOwnerType.code',
        value: code
      });
    }

    return of({
      status: true,
      content: null,
      jobId: this.jobId
    }).pipe(take(1));
  }
}
