import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {AuthenticationService} from '../../../../../../core/authentication/authentication.service';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../../environments';
import {Router} from '@angular/router';
import {SetTodoOpenStateFilterCondition} from '../../../../../job-runner/condition/set-todo-open-state-filter.condition';
import {JobContainerService} from '../../../../../../core/job-runner/job-container.service';
import {SetTodoOpenStateFilterJob} from '../../../../../job-runner/job/set-todo-open-state-filter.job';

@Component({
  selector: 'app-custom-staff-cockpit-open-todo-count',
  styleUrls: ['./staff-cockpit-open-todo-count.component.scss'],
  templateUrl: './staff-cockpit-open-todo-count.component.html',
})
export class StaffCockpitOpenTodoCountComponent implements OnInit, OnDestroy {

  public isLoading = true;
  public unsubscribe = new Subject<void>();
  public leasedEmployeeOpenTodoCount: 0;
  public customerOpenTodoCount = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private genericCrudService: GenericCrudService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private jobContainerService: JobContainerService
  ) {
  }

  ngOnInit(): void {
    const userId = this.authenticationService.currentUser.id;
    this.genericCrudService.getEntities('phoenix/leasedemployeetodos/offset/0/limit/50/orderby/id/asc', '', {
      onlyCount: true,
      todoState: 1,
      responsibleUser: userId
    }).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(({ total }) => {
      this.leasedEmployeeOpenTodoCount = total;
    })
    this.genericCrudService.getEntities('phoenix/customertodos/offset/0/limit/50/orderby/id/asc', '', {
      onlyCount: true,
      todoState: 1,
      responsibleUser: userId
    }).pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(({ total }) => {
      this.customerOpenTodoCount = total;
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onOpenCustomerOpenTodos(event): void {
    const todoLink = environment.myTodoModuleUrl;

    const condition = new SetTodoOpenStateFilterCondition();
    condition.setPayload({
      todoOwnerTypeCode: 'customer'
    });
    this.jobContainerService.registerJob(
      new SetTodoOpenStateFilterJob().setIsImmutable(true),
      condition
    );

    this.router.navigate([todoLink]);

    event.preventDefault();
  }

  onOpenLeasedEmployeeOpenTodos(event): void {
    const todoLink = environment.myTodoModuleUrl;

    const condition = new SetTodoOpenStateFilterCondition();
    condition.setPayload({
      todoOwnerTypeCode: 'leasedEmployee'
    });
    this.jobContainerService.registerJob(
      new SetTodoOpenStateFilterJob().setIsImmutable(true),
      condition
    );

    this.router.navigate([todoLink]);
    event.preventDefault();
  }
}

