import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';
import {Entity} from '../../../../../helpers/entity';
import {UserSessionService} from '../../../../../../core/service/user-session.service';
import {AuthenticationService} from '../../../../../../core/authentication/authentication.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';

export class UserProfitStatService {

  public constructor(
    private userSession: UserSessionService,
    private authenticationService: AuthenticationService,
    private genericCrudService: GenericCrudService
  ) {

  }

  public getStats(): Observable<any[]> {
    const params = {
      embedded: 'user',
      year: new Date().getFullYear()
    }

    return this.genericCrudService.getEntities('phoenix/userbranchofficeprofitstats', '', params)
      .pipe(
        map((stats) => {
          const statsCount = stats.length;
          stats.push({
            IS_SUMMARY: true,
            value: stats.reduce((accumulator, stat) => {
              return accumulator + +stat.value;
            }, 0) / statsCount
          });

          return stats.map((stat) => Entity.extractEmbedded(stat));
        })
      )
  }
}
