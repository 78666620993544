import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {CalendarLocaleService} from '../../../../../../core/service/locale/calendar-locale.service';
import {StatHoursPlanRealsService} from '../services/stat-hours-plan-reals.service';

interface ChartData {
  labels: string[];
  datasets: { label: string, data: number[], backgroundColor: string, borderColor: string }[]
}

interface ChartOptions {
  title: {
    display: boolean,
    text: string
  },
  legend: {
    position: 'bottom'
  }
}

@Component({
  selector: 'app-custom-staff-cockpit-stat-summed-count-chart',
  styleUrls: ['./staff-cockpit-stat-summed-count-chart.component.scss'],
  templateUrl: './staff-cockpit-stat-summed-count-chart.component.html',
  providers: [CalendarLocaleService, StatHoursPlanRealsService]
})
export class StaffCockpitStatSummedCountChartComponent implements OnInit, OnDestroy {

  public unsubscribe = new Subject<void>();
  public data: ChartData = undefined;
  public options: ChartOptions = {
    title: {
      display: true,
      text: 'Stunden Plan/Ist'
    },
    legend: {
      position: 'bottom'
    }
  };
  public isLoading = true;

  constructor(
    private calendarLocaleService: CalendarLocaleService,
    private cdr: ChangeDetectorRef,
    private statHoursPlanRealsService: StatHoursPlanRealsService
  ) {
  }

  ngOnInit(): void {
    this.statHoursPlanRealsService.getStats()
      .pipe(
        takeUntil(this.unsubscribe)
      ).subscribe((stats) => {
        this.data = this.buildData(stats);
        this.isLoading = false;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private buildData(stats): ChartData {
    const data = {
      labels: this.calendarLocaleService.getLocale().monthNames,
      datasets: [
        {
          label: 'Plan',
          backgroundColor: '#3883cb',
          borderColor: '#3883cb',
          data: []
        },
        {
          label: 'Ist',
          backgroundColor: '#e09354',
          borderColor: '#e09354',
          data: []
        }
      ]
    };

    for (const stat of stats) {
      data.datasets[0].data[stat.month - 1] = data.datasets[0].data[stat.month - 1] || 0;
      data.datasets[1].data[stat.month - 1] = data.datasets[1].data[stat.month - 1] || 0;
      data.datasets[0].data[stat.month - 1] += stat.plan;
      data.datasets[1].data[stat.month - 1] += stat.ist;
    }

    return data;
  }
}

