import {AbstractCondition} from '../../../core/job-runner/condition/abstract.condition';
import {JobContext} from '../../../core/job-runner/context/job.context';
import {RunnableEventRegistry} from '../../../core/job-runner/type/runnable-event.registry';
import {GenericTurboGridComponent} from '../../content-renderer/elements/generic-turbo-grid/generic-turbo-grid.component';

export class SetTodoOpenStateFilterCondition extends AbstractCondition {
  isApplicable(context: JobContext): boolean {
    const component = context.component;

    return context.event === RunnableEventRegistry.ContextCreated
      && component instanceof GenericTurboGridComponent
      && component.getElementDatamodelEntityName() === 'PhoenixBundle\\Entity\\Todo';
  }
}
