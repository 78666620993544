import {Component, OnInit, Input, ViewChildren, ElementRef, ViewChild, EventEmitter, AfterViewInit}
  from '@angular/core';
import {AbstractGenericGridComponent} from '../abstract-generic-grid.component';
import {GenericCrudService} from 'app/shared/services/generic-crud.service';
import {Debounce} from '../../../helpers/debounce';
import {LayoutService} from '../../../services/layout-service';
import {GridFilterDirective} from '../generic-grid/directives/grid-filter.directive';
import {TranslateService} from '@ngx-translate/core';
import {GenericElementFilterService} from '../../services/generic/filter/generic-element-filter.service';
import {FilterItem} from '../../../services/element/filter-item';
import {CustomFilterClassComponent} from './custom-filter-class/custom-filter-class.component';
import {GenericGridColumnBuilderService} from '../generic-grid/services/generic-grid-column-builder.service';
import {ElementState} from 'app/shared/content-renderer/services/element-state';

@Component({
  selector: 'app-generic-filter',
  styleUrls: ['./generic-filter.component.scss'],
  templateUrl: './generic-filter.component.html',
  providers: []
})
export class GenericFilterComponent implements OnInit, AfterViewInit {

  @Input() component: AbstractGenericGridComponent;

  @ViewChildren('customGridFilter') customGridFilters: CustomFilterClassComponent[] = [];
  @ViewChildren(GridFilterDirective) gridFilterDirectives: GridFilterDirective[];
  @ViewChild('filterContainer', {static: false}) filterContainer: ElementRef;

  public onSearchExecuted = new EventEmitter();

  public columns: any[] = [];
  public filterItems: FilterItem[];
  public componentState: ElementState = null;

  public dateRangeMinValue: number = (new Date).getFullYear() - 25;
  public dateRangeMaxValue: number = (new Date).getFullYear() + 25;
  public dateRangeValues: number[] = [];
  public selectableMonths: SelectItem[];

  public constructor(
    private genericCrudService: GenericCrudService,
    private layoutService: LayoutService,
    private translationService: TranslateService,
    private genericGridColumnBuilderService: GenericGridColumnBuilderService,
    private genericElementFilterService: GenericElementFilterService
  ) {
    this.dateRangeValues = [this.dateRangeMinValue, this.dateRangeMaxValue];
  }

  public ngOnInit() {
    this.filterItems = this.component.element.filterItems;
    this.componentState = this.component.componentState;

    for (const filterItem of this.filterItems) {
      filterItem.width = filterItem.width || '100px';
    }

    this.selectableMonths = this.genericElementFilterService.getSelectableMonths();
  }

  public ngAfterViewInit() {
    if(this.component.dataLoaded){

      Debounce.debounce(() => {
        this.component.loadEntities().subscribe();
      }, 500);

      this.onSearchExecuted.emit();
    }
  }

  public onFilter(event: any, filterItem: FilterItem): void {
    this.component.dataLoaded = false;
    this.component.onFilter(event, filterItem, false);
  }

  public onSearch(): void {
    this.component.currentOffset = 0;

    Debounce.debounce(() => {
      this.component.loadEntities().subscribe();
    }, 500);

    this.onSearchExecuted.emit();
  }

  public onFilterAutocompleteSearch(event: any, filterItem: FilterItem): void {
    let field = this.component.fields.find((field) => {
      return field.name === filterItem.fieldName;
    });
    let column = this.genericGridColumnBuilderService.getColumnFromField(field);
    this.component.onFilterAutocompleteSearch(event, column);
  }

  public onCancel() {
    this.component.isFilterContainerVisible = false;

    this.layoutService.onLayoutSizeChanged();
  }

  public onClear() {
    this.gridFilterDirectives.forEach((directive: GridFilterDirective) => {
      directive.setValue(this.component.componentState);
    });

    this.component.clearFilters()
      .initColumns()
      .clearEntities();
  }
}
