import {Observable} from 'rxjs/Observable';
import {UserSessionService} from '../../../../../../core/service/user-session.service';
import {AuthenticationService} from '../../../../../../core/authentication/authentication.service';
import {GenericCrudService} from '../../../../../services/generic-crud.service';
import {DateHelper} from '../../../../../helpers/date.helper';

export class StatHoursPlanRealsService {

  public constructor(
    private userSession: UserSessionService,
    private authenticationService: AuthenticationService,
    private genericCrudService: GenericCrudService
  ) {

  }

  public getStats(): Observable<any[]> {
    const params = {
      weekNumber: DateHelper.getCurrentWeek(new Date())
    }

    return this.genericCrudService.getEntities('phoenix/stathoursplanreals', '', params)
  }
}
