
import {of as observableOf, Observable} from 'rxjs';

import {ChangeDetectorRef, Component, Input, ViewChild, ViewContainerRef} from '@angular/core';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {GenericElementAbstract} from '../../generic-element-abstract.component';
import {FieldMetadataGrid} from '../../../../services/module/module-element-field-metadata-grid';
import {ModuleElement} from '../../../../services/module/module-element';
import {EntityValidator, EntityValidatorStatus} from '../../../../validators/services/entity-validator';
import {Element} from '../../../../services/element/element';
import {ToolbarItemCheckService} from '../../generic-toolbar/services/check/toolbar-item-check.service';
import {GenericElementValidationExecutionStepsFactory} from '../../../services/generic/generic-element-validation-execution-steps-factory';
import {EntityDataStoreService} from '../../../services/entity-data-store.service';
import {ModulesStateService} from '../../../services/modules-state.service';
import {ComponentService} from '../../../services/component-highlight-stack.service';
import {GenericCrudService} from '../../../../services/generic-crud.service';
import {LayoutService} from '../../../../services/layout-service';
import {JobContainerService} from '../../../../../core/job-runner/job-container.service';
import {ElementsStackService} from '../../../services/elements-stack.service';
import {ElementsStateService} from '../../../services/elements-state.service';
import {GenericDialogModuleService} from '../../generic-dialog/service/generic-dialog-module.service';
import {ExecutorActionsService} from '../../../../../core/executor/service/executor-actions/executor-actions.service';
import {PermissionService} from '../../../../services/permission/permission.service';
import {UserSessionService} from '../../../../../core/service/user-session.service';
import {GenericElementFilterService} from '../../../services/generic/filter/generic-element-filter.service';
import {TableColumn} from '../../../../dynamic-table/shared/table-column';
import {ChangeDetectorRefHelper} from '../../../../helpers/change-detector-ref.helper';
import {takeUntil} from 'rxjs/operators';
import {DynamicTableComponent} from '../../../../dynamic-table/dynamic-table.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Branch} from '../../../../services/branch/branch';

import * as moment from 'moment';

interface ActivityFormField {
  label: string;
  fieldPlan: string;
  fieldReal: string;
  editablePlan: boolean;
  editableReal: boolean;
}

@Component({
  selector: 'app-custom-user-activity-report',
  styleUrls: ['./user-activity-report.component.scss'],
  templateUrl: './user-activity-report.component.html',
  providers: [
    ExecutorService,
    GenericElementValidationExecutionStepsFactory,
    GenericElementFilterService
  ]
})
export class UserActivityReportComponent extends GenericElementAbstract {
  @Input() element: Element;
  @Input() fields: Array<FieldMetadataGrid>;
  @Input() toolbarItems: any[] = [];
  @Input() statusBarItems: any[] = [];
  @Input() moduleElement: ModuleElement;
  @Input() masterEntity: any = null;
  @Input() masterField: any = null;
  @Input() isPart = false;

  @ViewChild('table', {static: false}) table: DynamicTableComponent;

  public module = null;

  public toolbarContextName = 'userActivityReportComponent';

  public cells = [];
  public isLoading = false;

  public isStatisticsDialogVisible = false;
  public selectedStatisticEntity = null;

  public isActivityDialogVisible = false;
  public selectedActivityEntity = null;
  public userActivities = [];

  public form: FormGroup;

  public preparedFields: ActivityFormField[] = [];
  public preparedCustomerFields: ActivityFormField[] = [];
  public preparedEmployeeFields: ActivityFormField[] = [];

  public statisticEntities = [];
  public statisticColumns: TableColumn[] = [
    {key: 'user', header: 'Mitarbeiter'},
    {key: 'hours_planned_month', header: 'Std. Plan Monat'},
    {key: 'hours_real_month', header: 'Std. Ist lfd.'},
    {key: 'hours_planned_week', header: 'Plan Ma.'},
    {key: 'hours_real_week', header: 'Ist Ma. KW'},
    {key: 'target_percentage', header: 'Ziel'},
    {key: 'hours_employees_sick', header: 'Krank MA'},
    {key: 'hours_employees_holiday', header: 'Urlaub MA'},
    {key: 'hours_employees_no_assignment', header: 'ohne Auftrag'},
    {key: 'hours_planned_next_week', header: 'Plan MA KW+1'},
    {
      key: 'menu',
      header: '',
      style: {
        width: '30px'
      },
      menu: {
        buttons: [
          {click: this.onEditStatistics.bind(this), icon: 'fa fa-edit'}
        ]
      }
    },
  ];

  public activityEntities = [];
  public activityColumns: TableColumn[] = [
    {key: 'user', header: 'Mitarbeiter'},
    {key: 'VKt', header: 'VKt'},
    {key: 'bewv', header: 'bewv'},
    {key: 'bewpt', header: 'bewpt'},
    {key: 'betperM', header: 'betperM'},
    {key: 'VKt_total', header: 'VKt_total'},
    {key: 'bewv_total', header: 'bewv_total'},
    {key: 'bewpt_total', header: 'bewpt_total'},
    {key: 'betperM_total', header: 'betperM_total'},
    {
      key: 'menu',
      header: '',
      style: {
        width: '30px'
      },
      menu: {
        buttons: [
          {click: this.onEditActivity.bind(this), icon: 'fa fa-edit'}
        ]
      }
    },
  ];

  public activities = [];

  public selectedYear = null;
  public selectedWeek = null;
  public selectedBranchOffice = null;

  public forcedBranchOffice = null;
  public preselectedYear = null;
  public preselectedWeek = null;

  public constructor(
    protected componentService: ComponentService,
    protected viewContainerRef: ViewContainerRef,
    protected modulesStateService: ModulesStateService,
    protected genericCrudService: GenericCrudService,
    protected entityDataStoreService: EntityDataStoreService,
    protected executorService: ExecutorService,
    protected genericElementValidationExecutionStepsFactory: GenericElementValidationExecutionStepsFactory,
    protected entityValidator: EntityValidator,
    protected userSession: UserSessionService,
    protected toolbarItemCheckService: ToolbarItemCheckService,
    protected layoutService: LayoutService,
    protected jobContainerService: JobContainerService,
    protected elementsStackService: ElementsStackService,
    protected elementStateService: ElementsStateService,
    protected dialogService: GenericDialogModuleService,
    protected executorActionsService: ExecutorActionsService,
    protected permissionService: PermissionService,
    public cdr: ChangeDetectorRef,
    protected genericElementFilterService: GenericElementFilterService,
    protected formBuilder: FormBuilder,
  ) {
    super(componentService, viewContainerRef, entityDataStoreService, modulesStateService, executorService,
      genericElementValidationExecutionStepsFactory, entityValidator, genericCrudService, userSession, permissionService,
      cdr);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.genericCrudService.getEntities('intranet/activities')
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((activities) => this.activities = activities);

    const selectedBranchOffice  = this.userSession.get(Branch.LOCAL_STORAGE_NAME);

    if (selectedBranchOffice) {
      this.forcedBranchOffice = {
        label: '',
        value: selectedBranchOffice
      };

      this.selectedBranchOffice = selectedBranchOffice.id;
    }

    const date = moment();

    this.selectedYear = date.toDate().getFullYear().toString();
    this.selectedWeek = date.format('W').toString();

    this.preselectedYear = {
      label: this.selectedYear,
      value: {
        id: this.selectedYear
      }
    };
    this.preselectedWeek = {
      label: this.selectedWeek,
      value:  {
        id: this.selectedWeek
      }
    };

    this.search();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public getSelectedEntity(): any {
    return this.selectedMasterEntity || null;
  }

  public recheckToolbarItems(): void {
    this.toolbarItemCheckService.check(this);
  }

  public onSave(): Observable<any> {
    return observableOf(null);
  }

  public hasChanges(checkEmbedded: boolean = false): boolean {
    return false;
  }

  public onAfterSave(): Observable<any> {
    return observableOf(null);
  }

  public onChange(): Observable<any> {
    return observableOf(null);
  }

  public doValidate(): Observable<EntityValidatorStatus> {
    return observableOf({
      entity: null,
      isValid: true,
      error: '',
      errorFields: []
    });
  }

  public onRefresh(): Observable<any> {
    return observableOf(null);
  }

  public onBranchOfficeChanged(event): void {
    this.selectedBranchOffice = event.id;
    if (this.selectedWeek && this.selectedYear) {
      this.search();
    }
  }

  public onYearChanged(value): void {
    this.selectedYear = value.id;

    if (this.selectedWeek && this.selectedBranchOffice) {
      this.search();
    }
  }

  public onWeekChanged(value): void {
    this.selectedWeek = value.id;

    if (this.selectedYear && this.selectedBranchOffice) {
      this.search();
    }
  }

  public search(): void {
    if (!this.selectedWeek || !this.selectedYear || !this.selectedBranchOffice) {
      return;
    }

    this.isLoading = true;

    const params: Record<string, string> = {
      branch_office_id: this.selectedBranchOffice,
      year: this.selectedYear,
      week: this.selectedWeek
    }

    this.loadUserActivityStats('intranet/useractivitystats/load')
      .subscribe((entities) => {
        this.statisticEntities = [...entities];
        this.isLoading = false;
        ChangeDetectorRefHelper.detectChanges(this);
      });

    this.loadUserActivityStats('intranet/userweeklyactivitystats/load')
      .subscribe((entities) => {
        this.activityEntities = [...entities];
        this.isLoading = false;
        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  protected loadUserActivityStats(url): Observable<any> {
    const params: Record<string, string> = {
      branch_office_id: this.selectedBranchOffice,
      year: this.selectedYear,
      week: this.selectedWeek
    }

    return this.genericCrudService.getEntities(url, '', params)
      .pipe(
        takeUntil(this.unsubscribe)
      );
  }

  public onEditStatistics(entity) {
    this.selectedStatisticEntity = entity;
    this.isStatisticsDialogVisible = true;
  }
  public onSaveStatistics() {
    this.genericCrudService.customPut('intranet/useractivitystats/update', this.selectedStatisticEntity)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((response) => {
        this.isStatisticsDialogVisible = false;
        this.selectedStatisticEntity = null;
        ChangeDetectorRefHelper.detectChanges(this);
    });
  }

  public onEditActivity(entity) {
    this.userActivities = [];
    const formGroup = {};
    this.preparedFields = [];

    this.genericCrudService.getEntities('intranet/userweeklyactivities', '', {
      user: entity.user_id,
      currentWeek: this.selectedWeek,
      currentYear: this.selectedYear,
      embedded: 'activity'
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((userActivities) => {
        this.userActivities = userActivities;
        for (const activity of this.activities) {
          if (!activity.hasManualValuePlan && !activity.hasManualValueReal) {
            continue;
          }

          const userActivityEntity = this.userActivities.filter(userActivity => userActivity.activity.id === activity.id).pop();
          formGroup[activity.code + '_plan'] = this.formBuilder.control(userActivityEntity ? userActivityEntity.plannedValue : '0', [Validators.required]);
          formGroup[activity.code + '_real'] = this.formBuilder.control(userActivityEntity ? userActivityEntity.realValue : '0', [Validators.required]);

          if (activity.isCustomerActivity) {
            this.preparedCustomerFields.push({
              editablePlan: activity.hasManualValuePlan,
              editableReal: activity.hasManualValueReal,
              label: activity.name,
              fieldPlan: activity.code + '_plan',
              fieldReal: activity.code + '_real',
            });
          } else {
            this.preparedEmployeeFields.push({
              editablePlan: activity.hasManualValuePlan,
              editableReal: activity.hasManualValueReal,
              label: activity.name,
              fieldPlan: activity.code + '_plan',
              fieldReal: activity.code + '_real',
            });
          }
        }

        // set value:
        this.form = this.formBuilder.group(formGroup);
        this.isActivityDialogVisible = true;
        this.selectedActivityEntity = entity;
        ChangeDetectorRefHelper.detectChanges(this);
      });
  }

  public onSaveActivity() {
    this.doSaveActivity().pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  public onSaveAndCloseActivity() {
    this.doSaveActivity().pipe(takeUntil(this.unsubscribe)).subscribe((response) => {
      this.loadUserActivityStats('intranet/userweeklyactivitystats/load')
        .subscribe((entities) => {
          this.activityEntities = [...entities];
          this.isLoading = false;
          this.isActivityDialogVisible = false;
          this.selectedActivityEntity = null;
          this.userActivities = [];
          this.form = null;
          this.preparedFields = [];

          ChangeDetectorRefHelper.detectChanges(this);
        });
    });
  }

  private doSaveActivity(): Observable<any> {
    const data = [];

    for (const activity of this.activities) {
      data.push({
        week: this.selectedWeek,
        year: this.selectedYear,
        user_id: this.selectedActivityEntity.user_id,
        branch_office_id: this.selectedBranchOffice,
        activity_id: activity.id,
        plannedValue: this.form.value[activity.code + '_plan'],
        realValue: this.form.value[activity.code + '_real'],
      });
    }

    return this.genericCrudService.customPut('intranet/userweeklyactivitystats/update', data);
  }
}
