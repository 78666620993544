import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { PrettyJsonComponent } from 'angular2-prettyjson';
// import { AngularSplitModule } from './angular-split';

import { sharedExports } from './shared.exports';
import { sharedProviders } from './shared.providers';
import { sharedDeclarations } from './shared.declarations';
// import { LocalDataService } from './local-data/local-data.service';
import { AngularSplitModule } from './angular-split/angularSplit.module';
import { EntitySharingService } from './content-renderer/services/entity-sharing.service';

import { FormService } from './form-viewer/form.service';
import { FormViewerService } from './form-viewer/form-viewer.service';

import { DatamodelCrudService } from './services/datamodel/datamodel.crud.service';

import { ToolbarItemServiceFactory } from './content-renderer/elements/generic-toolbar/services/toolbar-item-service-factory.service';

import { ToolbarItemSave } from './content-renderer/elements/generic-toolbar/items/components/save/toolbar-item-save.component';
import { ToolbarItemPrint } from './content-renderer/elements/generic-toolbar/items/components/print/toolbar-item-print.component';
import { ToolbarItemRefresh } from './content-renderer/elements/generic-toolbar/items/components/refresh/toolbar-item-refresh.component';
import { ToolbarItemCancel } from './content-renderer/elements/generic-toolbar/items/components/cancel/toolbar-item-cancel.component';
import { ToolbarItemShowDeleted } from './content-renderer/elements/generic-toolbar/items/components/show-deleted/toolbar-item-show-deleted.component';
import { ToolbarItemShowExpired } from './content-renderer/elements/generic-toolbar/items/components/show-expired/toolbar-item-show-expired.component';
import { ToolbarItemShowWithoutMasterFilterField } from './content-renderer/elements/generic-toolbar/items/components/show-without-master-filter-field/toolbar-item-show-without-master-filter-field.component';
import { ComponentService } from './content-renderer/services/component-highlight-stack.service';
import { ToolbarItemAdd } from './content-renderer/elements/generic-toolbar/items/components/add/toolbar-item-add.component';
import { ToolbarItemDelete } from './content-renderer/elements/generic-toolbar/items/components/delete/toolbar-item-delete.component';
import { ToolbarItemDraftSave } from './content-renderer/elements/generic-toolbar/items/components/draft-save/toolbar-item-draft-save.component';
import { ToolbarItemDraftDelete } from './content-renderer/elements/generic-toolbar/items/components/draft-delete/toolbar-item-draft-delete.component';
import { ToolbarItemDetails } from './content-renderer/elements/generic-toolbar/items/components/details/toolbar-item-details.component';
import { ToolbarItemReorderColumns } from './content-renderer/elements/generic-toolbar/items/components/reorder-columns/toolbar-item-reorder-columns.component';

import { GenericToolbarComponent } from './content-renderer/elements/generic-toolbar/generic-toolbar.component';
import { ToolbarItemEdit } from './content-renderer/elements/generic-toolbar/items/components/edit/toolbar-item-edit.component';
import { ToolbarItemOpenElement } from './content-renderer/elements/generic-toolbar/items/components/open-element/toolbar-item-open-element.component';
import { ToolbarItemOpenModule } from './content-renderer/elements/generic-toolbar/items/components/open-module/toolbar-item-open-module.component';
import { ToolbarItemCollectiveContractManageParameters } from './content-renderer/elements/generic-toolbar/items/components/collective-contract-parameters/toolbar-item-collective-contract-manage-parameters.component';
import { ToolbarItemUploadDocument } from './content-renderer/elements/generic-toolbar/items/components/upload-document/toolbar-item-upload-document.component';
import { ToolbarItemWordExport } from './content-renderer/elements/generic-toolbar/items/components/word-export/toolbar-item-word-export.component';
import { ToolbarItemCompanySearch } from './content-renderer/elements/generic-toolbar/items/components/company-search/toolbar-item-company-search.component';
import { ToolbarItemCopy } from './content-renderer/elements/generic-toolbar/items/components/copy/toolbar-item-copy.component';
import { ToolbarItemOpenLink } from './content-renderer/elements/generic-toolbar/items/components/open-link/toolbar-item-open-link.component';
import { ToolbarItemFormCancel } from './content-renderer/elements/generic-toolbar/items/components/form/toolbar-item-form-cancel.component';
import { ToolbarItemFormNew } from './content-renderer/elements/generic-toolbar/items/components/form/toolbar-item-form-new.component';
import { ToolbarItemFormNext } from './content-renderer/elements/generic-toolbar/items/components/form/toolbar-item-form-next.component';
import { ToolbarItemFormPrevious } from './content-renderer/elements/generic-toolbar/items/components/form/toolbar-item-form-previous.component';
import { ToolbarItemFormLast } from './content-renderer/elements/generic-toolbar/items/components/form/toolbar-item-form-last.component';
import { ToolbarItemFormFirst } from './content-renderer/elements/generic-toolbar/items/components/form/toolbar-item-form-first.component';
import { ToolbarItemFormCounter } from './content-renderer/elements/generic-toolbar/items/components/form/toolbar-item-form-counter.component';
import { CompanyCommunicationService } from './company-search/services/company-communication.service';
import { ToolbarItemFormAddAddress } from './content-renderer/elements/generic-toolbar/items/components/form/add-address/toolbar-item-form-add-address.component';
import { PermissionService } from './services/permission/permission.service';
import { LayoutService } from './services/layout-service';
import { EntityFactoryService } from './services/entity-factory.service';
import { DoubleClickService } from './content-renderer/services/double-click.service';
import { ToolbarItemDeleteFilters } from './content-renderer/elements/generic-toolbar/items/components/delete-filters/toolbar-item-delete-filters.component';
import { FieldActionsService } from './content-renderer/services/field-actions.service';
import { FieldActionOpenElementComponent } from './content-renderer/elements/generic-grid/field-actions/open-element-action/field-action-open-element.component';
import { FieldActionOpenModuleComponent } from './content-renderer/elements/generic-grid/field-actions/open-module-action/field-action-open-module.component';
import { ActionRegistryService } from './services/action/action-registry.service';
import { FieldActionSetValueToAnotherFieldComponent } from './content-renderer/elements/generic-grid/field-actions/set-value-to-another-field-action/field-action-set-value-to-another-field.component';
import { FieldActionSetExtraParamFromAnotherFieldComponent } from './content-renderer/elements/generic-grid/field-actions/set-extra-param-from-another-field-action/field-action-set-extra-param-from-another-field.component';
import { ToolbarItemSendSms } from "./content-renderer/elements/generic-toolbar/items/components/notification/toolbar-item-send-sms.component";
import { HttpErrorResponseService } from './services/http-error-response-message.service';
import { FormActionsService } from 'app/shared/form-viewer/actions/services/form-actions.service';
import { FormActionFactoryService } from './form-viewer/actions/services/form-action-factory.service';
import { ToolbarItemBack } from "./content-renderer/elements/generic-toolbar/items/components/back/toolbar-item-back.component";
import { ToolbarItemDisabledCheckFactoryService } from "./content-renderer/elements/generic-toolbar/services/check/toolbar-item-disabled-check-factory.service";
import { ToolbarItemCheckService } from './content-renderer/elements/generic-toolbar/services/check/toolbar-item-check.service';
import { ToolbarItemWordExportConfiguration } from './content-renderer/elements/generic-toolbar/items/components/word-export-configuration/toolbar-item-word-export-configuration.component';
import { DataModelAbbrevationCrudService } from './services/datamodel/data-model-abbrevation.crud.service';
import {FieldActionSetOverallWorkingTimeComponent} from "./content-renderer/elements/generic-grid/field-actions/set-overall-working-time/field-action-set-overall-working-time.component";
import { ToolbarItemBulkAddComponent } from './content-renderer/elements/generic-toolbar/items/components/bulk-add/toolbar-item-bulk-add.component';
import { ToolbarItemOpenFilters } from './content-renderer/elements/generic-toolbar/items/components/open-filters/toolbar-item-open-filters.component';
import {GenericDialogModuleComponent} from './content-renderer/elements/generic-dialog/generic-dialog-module.component';
import {FieldActionSetPlannedWorkingTimeComponent} from "./content-renderer/elements/generic-grid/field-actions/set-planned-working-time/field-action-set-planned-working-time.component";
import {ToolbarItemEntityImportDialog} from './content-renderer/elements/generic-toolbar/items/components/entity-import/toolbar-item-entity-import-dialog.component';
import {ToolbarItemImportDefaultsDialog} from './content-renderer/elements/generic-toolbar/items/components/import-defaults/toolbar-item-import-defaults-dialog.component';
import {ToolbarItemEntityImport} from './content-renderer/elements/generic-toolbar/items/components/entity-import/toolbar-item-entity-import.component';
import { ToolbarItemSalaryTypeImportDialog } from './content-renderer/elements/generic-toolbar/items/components/salary-type-import-dialog/toolbar-item-salary-type-import-dialog.component';
import { ToolbarItemSalaryTypeSplit } from './content-renderer/elements/generic-toolbar/items/components/salary-type-split/salary-type-split.component';
import { InquiryPositionActionColumnComponent } from './content-renderer/elements/generic-grid/columns/inquiry/inquiry-position-action-column.component';
import { sharedGenericColumnDeclarations } from './shared-generic-column-declarations';
import {ToolbarItemTakeAddressToMasterComponent} from './content-renderer/elements/generic-toolbar/items/components/import-elected-to-master/toolbar-item-take-address-to-master.component';
import {FieldActionDisableAnotherFieldComponent} from './content-renderer/elements/generic-grid/field-actions/disable-another-field-action/field-action-disable-another-field.component';
import {FieldActionDisableSelfBaseOnAnotherFieldComponent} from './content-renderer/elements/generic-grid/field-actions/disable-self-base-on-another-field/field-action-disable-self-base-on-another-field.component';
import {ToolbarItemImportMultiselectedToMasterComponent} from './content-renderer/elements/generic-toolbar/items/components/import-multiselected-to-master/toolbar-item-import-multiselected-to-master.component';
import {ToolbarItemOpenModuleAssignmentsComponent} from './content-renderer/elements/generic-toolbar/items/components/open-module/assignments/toolbar-item-open-module-assignments.component';
import {FieldActionDisableSelfBasedOnOpenQuantityComponent} from './content-renderer/elements/generic-grid/field-actions/disable-self-based-on-open-quantity/field-action-disable-self-based-on-open-quantity.component';
import {ToolbarItemAddWhenComponentIsNotChangedComponent} from './content-renderer/elements/generic-toolbar/items/components/add-when-component-is-not-changed/toolbar-item-add-when-component-is-not-changed.component';
import {FieldActionGetCollectiveAgreementValidToComponent} from './content-renderer/elements/generic-grid/field-actions/CollectiveAgreement/field-action-get-collective-agreement-valid-to.component';
import {GenericTurboGridComponent} from './content-renderer/elements/generic-turbo-grid/generic-turbo-grid.component';
import {FieldActionOpenConditionModuleComponent} from './content-renderer/elements/generic-grid/field-actions/open-module-action/field-action-open-condition-module.component';
import {ToolbarItemSetOrderOfferSendStateComponent} from './content-renderer/elements/generic-toolbar/items/components/orderOfferState/toolbar-item-set-order-offer-send-state.component';
import {ToolbarItemSetOrderOfferConfirmedStateComponent} from './content-renderer/elements/generic-toolbar/items/components/orderOfferState/toolbar-item-set-order-offer-confirmed-state.component';
import {ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent} from './content-renderer/elements/generic-toolbar/items/components/salary-type-create-articles-from-selection/toolbar-item-salary-type-create-articles-from-selection.component';
import {ToolbarItemOpenNewOfferComponent} from './content-renderer/elements/generic-toolbar/items/components/open-new-offer/toolbar-item-open-new-offer.component';
import {ToolbarItemOpenNewOrderComponent} from './content-renderer/elements/generic-toolbar/items/components/open-new-order/toolbar-item-open-new-order.component';
import {ToolbarItemGenerateWorkDatesComponent} from './content-renderer/elements/generic-toolbar/items/components/assignment/toolbar-item-generate-work-dates.component';
import {ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent} from './content-renderer/elements/generic-toolbar/items/components/open-module/assignments/toolbar-item-open-module-assignments-leased-employee.component';
import {ToolbarItemOpenModuleAssignmentsSelectedLeasedEmployeeComponent} from './content-renderer/elements/generic-toolbar/items/components/open-module/assignments/toolbar-item-open-module-assignments-selected-leased-employee.component';
import {ToolbarItemOpenModuleJobAdvertisementComponent} from './content-renderer/elements/generic-toolbar/items/components/open-module/job-advetisement/toolbar-item-open-module-job-advertisement.component';
import {CancelComponentChangesService} from './content-renderer/services/cancel-components-changes.service';
import {ToolbarItemOpenModuleContractOfEmploymentComponent} from './content-renderer/elements/generic-toolbar/items/components/open-module/contract-of-employment/toolbar-item-open-module-contract-of-employment.component';
import {ToolbarItemOpenModuleContractOfEmployment2Component} from './content-renderer/elements/generic-toolbar/items/components/open-module/contract-of-employment/toolbar-item-open-module-contract-of-employment2.component';
import {sharedQuestionComponentDeclarations} from './shared-question-component-declarations';
import {ToolbarItemPhoneProjectCreatorComponent} from './content-renderer/elements/generic-toolbar/items/components/phone-project-creator/toolbar-item-phone-project-creator.component';
import {ToolbarItemCreateLeasedEmployeeFromCvComponent} from "./content-renderer/elements/generic-toolbar/items/components/create-leased-employee-from-cv/toolbar-item-create-leased-employee-from-cv.component";
import {FieldActionExecuteStepComponent} from './content-renderer/elements/generic-grid/field-actions/execute-step/field-action-execute-step.component';
import {ToolbarItemCaWageCalculationComponent} from './content-renderer/elements/generic-toolbar/items/components/collective-agreement/wage-calculation/toolbar-item-ca-wage-calculation.component';
import {ToolbarReportExportComponent} from './content-renderer/elements/generic-toolbar/items/components/report-export/toolbar-report-export.component';
import {ToolbarItemCalculationResultComponent} from './content-renderer/elements/generic-toolbar/items/components/collective-agreement/calculation-result/toolbar-item-calculation-result.component';
import { ReportComponent } from './content-renderer/elements/custom/report/report.component';
import {FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent} from './content-renderer/elements/generic-grid/field-actions/set-extra-param-from-one-multiselect-filter-to-another/field-action-set-extra-param-from-one-multiselect-filter-to-another.component';
import {FieldActionSetExtraParamFromOneMultiselectEditToAnotherComponent} from './content-renderer/elements/generic-grid/field-actions/set-extra-param-from-one-multiselect-edit-to-another/field-action-set-extra-param-from-one-multiselect-edit-to-another.component';
import {ToolbarItemUpdateLeasedEmployeeByDocumentComponent} from './content-renderer/elements/generic-toolbar/items/components/create-leased-employee-from-cv/toolbar-item-update-leased-employee-by-document.component';
import {ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent} from './content-renderer/elements/generic-toolbar/items/components/open-questionnaire/toolbar-item-open-questionnaire-by-phone-project-group.component';
import {ToolbarItemExecuteStepComponent} from './content-renderer/elements/generic-toolbar/items/components/execute-step/toolbar-item-execute-step.component';
import { ToolbarItemUnDeleteComponent } from './content-renderer/elements/generic-toolbar/items/components/un-delete/toolbar-item-un-delete.component';
import {ToolbarItemImportMultiselectedInModuleContext} from './content-renderer/elements/generic-toolbar/items/components/import-multiselected-to-master/toolbar-item-import-multiselected-in-module-context.component';
import {ToolbarItemShowIgnoreBranchOfficeComponent} from './content-renderer/elements/generic-toolbar/items/components/show-ignore-branch-office/toolbar-item-show-ignore-branch-office.component';
import {ToolbarItemUploadXmlDocumentComponent} from './content-renderer/elements/generic-toolbar/items/components/upload-document/toolbar-item-upload-xml-document.component';
import {ToolbarItemAddPreCalculationArticleComponent} from './content-renderer/elements/generic-toolbar/items/components/pre-calculation-article/toolbar-item-add-pre-calculation-article.component';
import {ToolbarItemSavePreCalculationArticleComponent} from './content-renderer/elements/generic-toolbar/items/components/pre-calculation-article/toolbar-item-save-pre-calculation-article.component';
import {ToolbarItemSavePrecalculationArticleService} from './content-renderer/elements/generic-toolbar/services/save/toolbar-item-save-precalculation-article.service';
import {ToolbarItemUploadLeasedEmployeeDocumentComponent} from './content-renderer/elements/generic-toolbar/items/components/upload-document/toolbar-item-upload-leased-employee-document.component';
import {SearchManagementComponent} from './components/expert-search/search-management.component';
import {ToolbarItemExpertSearchComponent} from './content-renderer/elements/generic-toolbar/items/components/expert-search/toolbar-item-expert-search.component';
import {ToolbarItemCaContractOfEmploymentComponent} from './content-renderer/elements/generic-toolbar/items/components/collective-agreement/contractOfEmployment/toolbar-item-ca-contract-of-employment.component';
import {ToolbarItemAddPreCalculationComponent} from './content-renderer/elements/generic-toolbar/items/components/add-pre-calculation/toolbar-item-add-pre-calculation.component';
import {CustomButtonCheckFactoryService} from './content-renderer/elements/generic-grid/services/custom-button-check/custom-button-check-factory.service';
import {ToolbarItemOpenQuickLinkComponent} from './content-renderer/elements/generic-toolbar/items/components/open-quick-link/toolbar-item-open-quick-link.component';
import {ToolbarItemSyncSalaryTypesComponent} from './content-renderer/elements/generic-toolbar/items/components/collective-agreement/salary-type-sync/toolbar-item-sync-salary-types.component';
import {ToolbarItemOpenModuleSelectedSkillsOfLeasedEmployeeComponent} from './content-renderer/elements/generic-toolbar/items/components/open-module/leased-employee/toolbar-item-open-module-selected-skills-of-leased-employee.component';
import {ToolbarItemAddSkillsToLeasedEmployeeComponent} from './content-renderer/elements/generic-toolbar/items/components/open-module/leased-employee/toolbar-item-add-skills-to-leased-employee.component';
import {ToolbarItemPreCalculationShowFactorsComponent} from './content-renderer/elements/generic-toolbar/items/components/pre-calculation-article/toolbar-item-pre-calculation-show-factors.component';
import {ToolbarItemReleasePreCalculationComponent} from './content-renderer/elements/generic-toolbar/items/components/pre-calculation-article/toolbar-item-release-pre-calculation.component';
import {sharedToastDeclarations} from './shared-toast-declarations';
import {ToolbarItemImportCloudPrinterComponent} from './content-renderer/elements/generic-toolbar/items/components/import-cloud-printer/toolbar-item-import-cloud-printer.component';
import {ToolbarItemUploadGkkDocumentComponent} from "./content-renderer/elements/generic-toolbar/items/components/upload-document/toolbar-item-upload-gkk-document.component";
import {ToolbarItemSimpleSearchComponent} from './content-renderer/elements/generic-toolbar/items/components/simple-search/toolbar-item-simple-search.component';
import {SimpleSearchService} from './services/simple-search/simple-search.service';
import {SimpleSearchCacheService} from './services/simple-search/simple-search-cache.service';
import {ToolbarItemImportSpecialAllowancesComponent} from './content-renderer/elements/generic-toolbar/items/components/pre-calculation-article/toolbar-item-import-special-allowances.component';
import {ToolbarItemSyncArticlesComponent} from './content-renderer/elements/generic-toolbar/items/components/pre-calculation-article/toolbar-item-sync-articles.component';
import {ToolbarItemKsvCompanySearchComponent} from './content-renderer/elements/generic-toolbar/items/components/ksv-company-search/toolbar-item-ksv-company-search.component';
import {ToolbarItemBdmExportComponent} from "./content-renderer/elements/generic-toolbar/items/components/bdm-export/toolbar-item-bdm-export.component";
import {ToolbarItemSaveGroupedArticleComponent} from './content-renderer/elements/generic-toolbar/items/components/pre-calculation-article/toolbar-item-save-grouped-article.component';
import {GenericGridSingleEntitySaveService} from './content-renderer/elements/generic-grid/services/generic-grid-single-entity-save.service';
import {ToolbarItemResetPreCalculationArticleComponent} from './content-renderer/elements/generic-toolbar/items/components/pre-calculation-article/toolbar-item-reset-pre-calculation-article.component';
import {GenericGridLayoutService} from './content-renderer/elements/generic-grid/services/generic-grid-layout.service';
import {GenericTurboGridLayoutService} from './content-renderer/elements/generic-turbo-grid/service/generic-turbo-grid-layout-service';
import {ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent} from './content-renderer/elements/generic-toolbar/items/components/leased-employee/toolbar-item-show-or-hide-contract-of-employment-exit-information.component';
import {FieldActionCalculateSalaryComponent} from "./content-renderer/elements/generic-grid/field-actions/workhour-calculate-salary/field-action-calculate-salary.component";
import {ToolbarItemCaWorkhourFlattenComponent} from './content-renderer/elements/generic-toolbar/items/components/collective-agreement/workhour-flatten/toolbar-item-ca-workhour-flatten.component';
import {ToolbarItemExportReportComponent} from './content-renderer/elements/generic-toolbar/items/components/lga/toolbar-item-export-report.component';
import {ToolbarItemCaWorkhourUnflattenComponent} from './content-renderer/elements/generic-toolbar/items/components/collective-agreement/workhour-unflatten/toolbar-item-ca-workhour-unflatten.component';
import {ToolbarItemWorkHourMonthlyGridShowWageAndInvoiceColumnsComponent} from './content-renderer/elements/generic-toolbar/items/components/work-hour/toolbar-item-work-hour-monthly-grid-show-wage-and-invoice-columns.component';
import {ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent} from './content-renderer/elements/generic-toolbar/items/components/work-hour/toolbar-item-work-hour-monthly-grid-toggle-between-wage-and-invoice-columns.component';
import {ToolbarItemWorkHourMonthlyGridRecalculateComponent} from './content-renderer/elements/generic-toolbar/items/components/work-hour/toolbar-item-work-hour-monthly-grid-recalculate.component';
import {ToolbarItemCaWorkhourInvoiceFlattenComponent} from './content-renderer/elements/generic-toolbar/items/components/collective-agreement/workhour-invoice-flatten/toolbar-item-ca-workhour-invoice-flatten.component';
import {ToolbarItemCaWorkhourInvoiceUnflattenComponent} from './content-renderer/elements/generic-toolbar/items/components/collective-agreement/workhour-invoice-unflatten/toolbar-item-ca-workhour-invoice-unflatten.component';
import {ToolbarItemStornoContractOfEmploymentComponent} from "./content-renderer/elements/generic-toolbar/items/components/open-module/contract-of-employment/toolbar-item-storno-contract-of-employment.component";
import {ToolbarItemCaBulkCalculateComponent} from './content-renderer/elements/generic-toolbar/items/components/collective-agreement/workhour-bulk-calculate/toolbar-item-ca-bulk-calculate.component';
import {ToolbarItemStornoInvoiceComponent} from "./content-renderer/elements/generic-toolbar/items/components/invoice/toolbar-item-storno-invoice.component";
import {ToolbarItemDownloadBulkDocuwareComponent} from "./content-renderer/elements/generic-toolbar/items/components/docuware/toolbar-item-download-bulk-docuware.component";
import {ToolbarItemFilter} from './content-renderer/elements/generic-toolbar/items/components/toolbar-item-filter.component';
import {ToolbarItemWorkHourMonthlyGridSelectionComponent} from "./content-renderer/elements/generic-toolbar/items/components/work-hour-monthly/toolbar-item-work-hour-monthly-grid-selection.component";
import {ToolbarItemDownloadTemplatesComponent} from "./content-renderer/elements/generic-toolbar/items/components/print-template/toolbar-item-download-templates.component";
import {ToolbarItemColoringFactoryService} from './content-renderer/elements/generic-toolbar/services/color/toolbar-item-coloring-factory.service';
import {FieldActionSetValueToAnotherFieldFromEntityComponent} from "./content-renderer/elements/generic-grid/field-actions/set-value-to-another-field-action/field-action-set-value-to-another-field-from-entity.component";
import {ToolbarItemWorkingModelTimeDecimalSwitchComponent} from "./content-renderer/elements/generic-toolbar/items/components/working-model-time-decimal-switch/toolbar-item-working-model-time-decimal-switch.component";
import {ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent} from './content-renderer/elements/generic-toolbar/items/components/working-model-time-decimal-switch/toolbar-item-selected-working-model-day-decimal-switch.component';
import {ToolbarItemUnlockFormComponent} from './content-renderer/elements/generic-toolbar/items/components/contract-of-employment/toolbar-item-unlock-form.component';
import {ToolbarItemUnlockColumnComponent} from './content-renderer/elements/generic-toolbar/items/components/Unlock-Grid-Column/toolbar-item-unlock-column.component';
import {ToolbarItemAuditComponent} from './content-renderer/elements/generic-toolbar/items/components/audit/toolbar-item-audit.component';
import {ToolbarItemCsvExportComponent} from './content-renderer/elements/generic-toolbar/items/components/csv-export/toolbar-item-csv-export.component';
import {ToolbarItemImportSftpPrinterComponent} from './content-renderer/elements/generic-toolbar/items/components/import-sftp-printer/toolbar-item-import-sftp-printer.component';
import {ToolbarItemUserImportComponent} from './content-renderer/elements/generic-toolbar/items/components/user-import/toolbar-item-user-import.component';
import {ToolbarItemMasterSaveComponent} from './content-renderer/elements/generic-toolbar/items/components/master-save/toolbar-item-master-save.component';
import {WorkflowActionParameterFormComponent} from './content-renderer/elements/generic-grid/columns/workflow/workflow-action-parameter-form.component';
import {DuplicateEntitiesGridComponent} from './form-viewer/actions/handlers/duplicate-entity-check/duplicate-entities-grid.component';
import {ToolbarItemRunCronjobComponent} from './content-renderer/elements/generic-toolbar/items/components/cron/toolbar-item-run-cronjob.component';
import {FieldActionInquiryPositionSetPrecalculationValuesComponent} from './content-renderer/elements/generic-grid/field-actions/inquiry-position/field-action-inquiry-position-set-precalculation-values.component';
import {ToolbarEntityPreviewComponent} from './content-renderer/elements/generic-toolbar/items/components/collective-agreement/entity-preview/toolbar-entity-preview.component';
import {ToolbarItemValidateCaEntitiesComponent} from './content-renderer/elements/generic-toolbar/items/components/collective-agreement/validate-ca-entities/toolbar-item-validate-ca-entities.component';
import {ToolbarItemSaveAndCloseStepComponent} from './content-renderer/elements/generic-toolbar/items/components/workflow/save-and-close-step/toolbar-item-save-and-close-step.component';
import {ToolbarItemElbaCsvExportComponent} from './content-renderer/elements/generic-toolbar/items/components/elba-csv-export/toolbar-item-elba-csv-export.component';
import {ToolbarItemDisplayDmsNotesComponent} from './content-renderer/elements/generic-toolbar/items/components/display-dms-notes/toolbar-item-display-dms-notes.component';
import {ToolbarItemWordExportInvoice} from './content-renderer/elements/generic-toolbar/items/components/word-export/toolbar-item-word-export-invoice.component';
import {ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent} from './content-renderer/elements/generic-toolbar/items/components/open-module/order/toolbar-item-open-module-depending-on-order-is-mediation-value.component';
import {ToolbarItemOpenMemoModuleComponent} from './content-renderer/elements/generic-toolbar/items/components/open-module/toolbar-item-open-memo-module.component';
import {
  ToolbarItemSaveAndSendWorkflowComponent
} from './content-renderer/elements/generic-toolbar/items/components/save-and-send-workflow/toolbar-item-save-and-send-workflow.component';
import {
  ToolbarItemSaveAndTriggerWorkflowComponent
} from './content-renderer/elements/generic-toolbar/items/components/save-and-trigger-workflow/toolbar-item-save-and-trigger-workflow.component';
import {
  ToolbarItemUploadAttachmentTypeComponent
} from './content-renderer/elements/generic-toolbar/items/components/upload-document/toolbar-item-upload-attachment-type.component';


@NgModule({
  imports: [
    CommonModule,
    ...sharedExports
  ],
  declarations: [
    ...sharedDeclarations,
    ...sharedGenericColumnDeclarations,
    ...sharedQuestionComponentDeclarations,
    ...sharedToastDeclarations,
    ReportComponent,
    // LocalDataService
    // ...SharedProviders
  ],
  providers: [
    EntitySharingService,
    DoubleClickService,
    FieldActionsService,
    ComponentService,
    LayoutService,
    HttpErrorResponseService,
    ToolbarItemServiceFactory,
    ToolbarItemSavePrecalculationArticleService,
    CustomButtonCheckFactoryService,
    GenericGridSingleEntitySaveService,
    GenericGridLayoutService,
    GenericTurboGridLayoutService,
    FormService,
    FormActionsService,
    CancelComponentChangesService,
    FormActionFactoryService,
    PermissionService,
    FormViewerService,
    ActionRegistryService,
    EntityFactoryService,
    ToolbarItemCheckService,
    ToolbarItemDisabledCheckFactoryService,
    ToolbarItemColoringFactoryService,
    DataModelAbbrevationCrudService,
    SimpleSearchService,
    SimpleSearchCacheService,
    // LocalDataService
    ...sharedProviders,
  ],
  exports: [
    ...sharedExports,
    ...sharedDeclarations,
    ...sharedGenericColumnDeclarations,
    ...sharedQuestionComponentDeclarations,
    ...sharedToastDeclarations,
    ...sharedProviders
  ],
  entryComponents: [
    ...sharedGenericColumnDeclarations,
    // PrettyJsonComponent,
    // @todo Entry Components at a shared module? This already sounds crazy..
    WorkflowActionParameterFormComponent,
    ToolbarItemAdd,
    ToolbarItemAuditComponent,
    ToolbarItemAddWhenComponentIsNotChangedComponent,
    ToolbarItemAddPreCalculationComponent,
    ToolbarItemBack,
    ToolbarItemUnDeleteComponent,
    ToolbarItemDeleteFilters,
    ToolbarItemOpenFilters,
    ToolbarItemEdit,
    ToolbarItemSave,
    ToolbarItemMasterSaveComponent,
    ToolbarItemExportReportComponent,
    ToolbarItemSavePreCalculationArticleComponent,
    ToolbarItemResetPreCalculationArticleComponent,
    ToolbarItemSaveGroupedArticleComponent,
    ToolbarItemPrint,
    ToolbarItemRefresh,
    ToolbarItemCancel,
    ToolbarItemDelete,
    ToolbarItemDraftSave,
    ToolbarItemDraftDelete,
    ToolbarItemBulkAddComponent,
    ToolbarItemSendSms,
    ToolbarItemDetails,
    ToolbarItemOpenElement,
    ToolbarItemOpenModule,
    ToolbarItemOpenMemoModuleComponent,
    ToolbarItemOpenNewOfferComponent,
    ToolbarItemOpenNewOrderComponent,
    ToolbarItemOpenQuestionnaireByPhoneProjectGroupComponent,
    ToolbarItemOpenQuickLinkComponent,
    ToolbarItemGenerateWorkDatesComponent,
    ToolbarItemAddPreCalculationArticleComponent,
    ToolbarItemImportSpecialAllowancesComponent,
    ToolbarItemSyncArticlesComponent,
    ToolbarItemOpenModuleAssignmentsComponent,
    ToolbarItemShowDeleted,
    ToolbarItemShowExpired,
    ToolbarItemShowIgnoreBranchOfficeComponent,
    ToolbarItemShowWithoutMasterFilterField,
    ToolbarItemCollectiveContractManageParameters,
    ToolbarItemPhoneProjectCreatorComponent,
    ToolbarItemUploadDocument,
    ToolbarItemUploadAttachmentTypeComponent,
    ToolbarItemUploadLeasedEmployeeDocumentComponent,
    ToolbarItemUploadXmlDocumentComponent,
    ToolbarItemUploadGkkDocumentComponent,
    ToolbarReportExportComponent,
    ToolbarItemCsvExportComponent,
    ToolbarItemElbaCsvExportComponent,
    ToolbarItemWordExport,
    ToolbarItemWordExportInvoice,
    ToolbarItemDownloadTemplatesComponent,
    ToolbarItemWorkHourMonthlyGridShowWageAndInvoiceColumnsComponent,
    ToolbarItemWorkHourMonthlyGridToggleBetweenWageAndInvoiceColumnsComponent,
    ToolbarItemWorkHourMonthlyGridRecalculateComponent,
    ToolbarItemWorkHourMonthlyGridSelectionComponent,
    ToolbarItemWorkingModelTimeDecimalSwitchComponent,
    ToolbarItemSelectedWorkingModelDayDecimalSwitchComponent,
    ToolbarItemImportCloudPrinterComponent,
    ToolbarItemImportSftpPrinterComponent,
    ToolbarItemPreCalculationShowFactorsComponent,
    ToolbarItemReleasePreCalculationComponent,
    ToolbarItemWordExportConfiguration,
    ToolbarItemFilter,
    ToolbarItemCompanySearch,
    ToolbarItemUserImportComponent,
    ToolbarItemKsvCompanySearchComponent,
    ToolbarItemShowOrHideContractOfEmploymentExitInformationComponent,
    ToolbarItemCopy,
    ToolbarItemBdmExportComponent,
    ToolbarItemCaWageCalculationComponent,
    ToolbarItemCaWorkhourFlattenComponent,
    ToolbarItemCaWorkhourInvoiceFlattenComponent,
    ToolbarItemCaWorkhourUnflattenComponent,
    ToolbarItemCaWorkhourInvoiceUnflattenComponent,
    ToolbarItemSyncSalaryTypesComponent,
    ToolbarItemCalculationResultComponent,
    ToolbarItemCaContractOfEmploymentComponent,
    ToolbarItemCaBulkCalculateComponent,
    ToolbarItemTakeAddressToMasterComponent,
    ToolbarItemSetOrderOfferSendStateComponent,
    ToolbarItemSetOrderOfferConfirmedStateComponent,
    ToolbarItemImportMultiselectedToMasterComponent,
    ToolbarItemImportMultiselectedInModuleContext,
    ToolbarItemOpenModuleAssignmentsLeasedEmployeeComponent,
    ToolbarItemAddSkillsToLeasedEmployeeComponent,
    ToolbarItemOpenModuleJobAdvertisementComponent,
    ToolbarItemOpenModuleAssignmentsSelectedLeasedEmployeeComponent,
    ToolbarItemOpenModuleSelectedSkillsOfLeasedEmployeeComponent,
    ToolbarItemOpenModuleDependingOnOrderIsMediationValueComponent,
    ToolbarItemOpenModuleContractOfEmploymentComponent,
    ToolbarItemOpenModuleContractOfEmployment2Component,
    ToolbarItemStornoContractOfEmploymentComponent,
    ToolbarItemStornoInvoiceComponent,
    ToolbarItemCreateLeasedEmployeeFromCvComponent,
    ToolbarItemUpdateLeasedEmployeeByDocumentComponent,
    ToolbarItemReorderColumns,
    ToolbarItemOpenLink,
    ToolbarItemUnlockColumnComponent,
    ToolbarItemUnlockFormComponent,
    ToolbarItemDownloadBulkDocuwareComponent,
    ToolbarItemFormCancel,
    ToolbarItemFormNew,
    ToolbarItemFormNext,
    ToolbarItemFormPrevious,
    ToolbarItemFormLast,
    ToolbarItemFormFirst,
    ToolbarItemFormCounter,
    ToolbarItemFormAddAddress,
    ToolbarItemEntityImportDialog,
    ToolbarItemImportDefaultsDialog,
    ToolbarItemEntityImport,
    ToolbarItemExecuteStepComponent,
    ToolbarItemExpertSearchComponent,
    ToolbarItemDisplayDmsNotesComponent,
    ToolbarItemSimpleSearchComponent,
    ToolbarItemSalaryTypeImportDialog,
    ToolbarItemSalaryTypeSplit,
    ToolbarItemSalaryTypeCreateArticlesFromSelectionComponent,
    ToolbarItemRunCronjobComponent,
    ToolbarEntityPreviewComponent,
    ToolbarItemValidateCaEntitiesComponent,
    ToolbarItemSaveAndCloseStepComponent,
    ToolbarItemSaveAndTriggerWorkflowComponent,
    ToolbarItemSaveAndSendWorkflowComponent,
    FieldActionOpenElementComponent,
    FieldActionOpenModuleComponent,
    FieldActionOpenConditionModuleComponent,
    FieldActionDisableAnotherFieldComponent,
    FieldActionDisableSelfBaseOnAnotherFieldComponent,
    FieldActionDisableSelfBasedOnOpenQuantityComponent,
    FieldActionGetCollectiveAgreementValidToComponent,
    FieldActionSetValueToAnotherFieldComponent,
    FieldActionSetValueToAnotherFieldFromEntityComponent,
    FieldActionSetOverallWorkingTimeComponent,
    FieldActionSetPlannedWorkingTimeComponent,
    FieldActionCalculateSalaryComponent,
    FieldActionSetExtraParamFromAnotherFieldComponent,
    FieldActionSetExtraParamFromOneMultiSelectFilterToAnotherComponent,
    FieldActionSetExtraParamFromOneMultiselectEditToAnotherComponent,
    FieldActionExecuteStepComponent,
    FieldActionInquiryPositionSetPrecalculationValuesComponent,
    GenericDialogModuleComponent,
    DuplicateEntitiesGridComponent,
    SearchManagementComponent,
    ...sharedQuestionComponentDeclarations,
    ...sharedToastDeclarations
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        DatamodelCrudService,
        CompanyCommunicationService
      ]
    };
  }
}
