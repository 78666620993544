
import {of as observableOf, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import { AbstractGenericGridComponent } from 'app/shared/content-renderer/elements/abstract-generic-grid.component';
import {ToolbarItemOpenModule} from '../toolbar-item-open-module.component';
import { cloneDeep } from 'lodash';
import {Guid} from 'guid-typescript';
import {MessageGrowlService} from '../../../../../../../../core/message/message-growl.service';
import {DoubleClickService} from '../../../../../../services/double-click.service';
import {GenericDialogModuleService} from '../../../../../generic-dialog/service/generic-dialog-module.service';
import {GenericCrudService} from '../../../../../../../services/generic-crud.service';
import {EntityDirtyStoreService} from '../../../../../../services/entity-dirty-store.service';
import {EntityManagerService} from '../../../../../../../../core/service/entity-manager/entity-manager.service';
import {ModulesStateService} from '../../../../../../services/modules-state.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-toolbar-item-open-module-contract-of-employment',
  template: ''
})
export class ToolbarItemOpenModuleContractOfEmploymentComponent extends ToolbarItemOpenModule {

  constructor(
    protected messageGrowlService: MessageGrowlService,
    protected doubleClickService: DoubleClickService,
    protected genericDialogModuleService: GenericDialogModuleService,
    protected genericCrudService: GenericCrudService,
    protected entityDirtyStoreService: EntityDirtyStoreService,
    protected entityManager: EntityManagerService,
    protected moduleService: ModulesStateService
  ) {
    super(messageGrowlService, doubleClickService, genericDialogModuleService, genericCrudService, entityManager);
  }

  protected doOpenModuleInGridContext(gridComponent: AbstractGenericGridComponent) {
    this.toolbarItem = this.getToolbarItem();

    gridComponent.triggerAddEntityActions();
    this.buildEntity(gridComponent).subscribe(entity => {
      this.entity = entity;
      this.entityDirtyStoreService.store(this.entity);

      if (!this.validateInGridContext(gridComponent)) {
        return this.messageGrowlService.error(this.getValidateMessage());
      }
      this.genericCrudService.getEntities(`superadmin/modules/${this.getToolbarItem().actionModuleId}`).subscribe((targetModule) => {
        this.genericDialogModuleService.showDialog(targetModule, {
          height: +this.getToolbarItem().targetDialogHeight,
          width: +this.getToolbarItem().targetDialogWidth,
          entity: this.entity,
          isAutocompleteModuleState: false,
          isModal: this.getToolbarItem().isTargetDialogModal,
          maximized: this.getToolbarItem().isTargetDialogMaximized,
          masterElementContext: gridComponent.getElementContext(),
          onClose: () => {
            const moduleState = this.moduleService.getById(targetModule.id);

            if (moduleState.isDialog && gridComponent instanceof AbstractGenericGridComponent) {
              const components = moduleState.getAllComponents();

              gridComponent.getCancelChangesService()
                .setExecutorService(gridComponent.getExecutor())
                .cancel(components);

              return false;
            }

            return true;
          }
        });
      });
    });

  }

  protected buildEntity(gridComponent: AbstractGenericGridComponent): Observable<any> {
    let contractSubNumber = null;
    const latestContract = this.getLatestContract(gridComponent);
    let newEntity = null;

    if (latestContract === null) {
      newEntity = cloneDeep(gridComponent.emptyEntity);
      return observableOf(newEntity);
    } else if (latestContract) {
      const severancePay = this.fetchSeverancePay(latestContract);
      if (new Date(latestContract.validFrom).getFullYear() !== new Date().getFullYear()) {
        contractSubNumber = 0;
      } else {
        contractSubNumber = this.fetchContractNumber(latestContract.contractSubNumber);
      }

      return this.genericCrudService.getEntity('phoenix/contractofemployments', latestContract.id).pipe(map(latestContract => {
        newEntity = cloneDeep(latestContract);
        newEntity.id = null;
        newEntity.uniqueId = Guid.create().toString();
        newEntity._links.self = null;
        newEntity._embedded = newEntity._embedded || {};
        this.entityManager.persist(newEntity, {property: 'copyOf', newValue: latestContract, force: true });

        this.entityManager.persist(newEntity, {property: 'agreementDate', newValue: new Date(), force: true });
        this.entityManager.persist(newEntity, {property: 'validFrom', newValue: new Date(), force: true });
        this.entityManager.persist(newEntity, {property: 'validTo', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'probationEndDate', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'exitActionDate', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'exitAgreementDate', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'exitAgreement', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'gradeNotes', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'grade', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'contractNumber', newValue: null, force: true });
        newEntity._embedded.grade = null;
        this.entityManager.persist(newEntity, {property: 'futureLeasedEmployeeState', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'exitReason', newValue: null, force: true });
        newEntity._embedded.exitReason = null;
        this.entityManager.persist(newEntity, {property: 'isLimitedTill', newValue: false, force: true });
        this.entityManager.persist(newEntity, {property: 'limitedTil', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'limitationNotes', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'eldaAutomaticallyRegistered', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'eldaManuallyRegistered', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'registrationDone', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'deregistrationDone', newValue: null, force: true });
        this.entityManager.persist(newEntity, {property: 'isDisabledAgreementDate', newValue: false, force: true });
        if (latestContract.exitAgreementDate === null || typeof latestContract.exitAgreementDate === 'undefined') {
          this.entityManager.persist(newEntity, {property: 'isDisabledAgreementDate', newValue: true, force: true });
        }
        console.log(contractSubNumber);
        this.entityManager.persist(newEntity, {property: 'severancePay', newValue: severancePay.name, force: true });
        this.entityManager.persist(newEntity, {property: 'contractSubNumber', newValue: contractSubNumber, force: true });
        newEntity._embedded.severancePay = null;
        newEntity._embedded.contractOfEmploymentState = null;

        return newEntity;
      }));
    }
  }

  protected getLatestContract(gridComponent: AbstractGenericGridComponent) {
    let latestContract = null;
    for (const contract of gridComponent.getEntities()) {
      if (latestContract === null && this.isNotCanceledContract(contract)) {
        latestContract = contract;
      } else if (contract.validFrom.valueOf() > latestContract.validFrom.valueOf()
        && this.isNotCanceledContract(contract)) {
        latestContract = contract;
      }
    }
    return latestContract;
  }

  protected isNotCanceledContract(contract): boolean {
    return !contract._embedded || !contract._embedded.contractOfEmploymentState || contract._embedded.contractOfEmploymentState.code !== 'DVSTORNO';
  }

  protected fetchSeverancePay(latestContract: any) {
    let severancePay = null;
    const validToDate = new Date(latestContract.validTo);
    const months = this.monthDiff(validToDate, new Date());
    const days = this.daysDiff(new Date(latestContract.validFrom), validToDate);

    if (!validToDate || months > 12) {
      this.genericCrudService.getEntity('phoenix/severancepays', 7).subscribe((pay) => {severancePay = pay});
    } else if (months < 12 && days > 30) {
      this.genericCrudService.getEntity('phoenix/severancepays', 6).subscribe((pay) => {severancePay = pay});
    } else {
      this.genericCrudService.getEntity('phoenix/severancepays', 7).subscribe((pay) => {severancePay = pay});
    }
    return severancePay;
  }

  protected monthDiff(dateFrom: Date, dateTo: Date) {
    return dateTo.getMonth() - dateFrom.getMonth() + (12 * (dateTo.getFullYear() - dateFrom.getFullYear()));
  }

  protected daysDiff(dateFrom: Date, dateTo: Date) {
    const time = dateTo.getTime() - dateFrom.getTime();
    return time / (1000 * 3600 * 24);
  }

  private fetchContractNumber(contractSubNumber: any): number {
    return Number(contractSubNumber) + 1;
  }
}
