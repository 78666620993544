import {Observable} from 'rxjs';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {GenericElementAbstract} from '../../../content-renderer/elements/generic-element-abstract.component';

export class RegisterSendMemoMessageExecutionStep extends AbstractExecutionStep {

  doExecute(): Observable<ExecutionStepStatus> {
    const component = this.getPayload().getValue();

    if (!(component instanceof GenericElementAbstract)) {
      return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
    }

    return this.doRegister(component);
  }

  private doRegister(component: GenericElementAbstract): Observable<ExecutionStepStatus> {
    return Observable.create((observer) => {

      const entity = component.getSelectedEntity();

      if (entity) {
        this.genericCrudService.get(component.getElementDataModelApiRoute() + '/send-message/' + entity.id).subscribe(response => {
          observer.next({
            status: entity !== null,
            content: null
          });
          observer.complete();
        });
      }
    });
  }
}
