import {Entity} from '../../../helpers/entity';
import {EntityManagerService} from '../../../../core/service/entity-manager/entity-manager.service';
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
import {GenericCrudService} from '../../generic-crud.service';
import {UserSessionService} from '../../../../core/service/user-session.service';
import {Branch} from '../../branch/branch';
import {AuthenticationService} from '../../../../core/authentication/authentication.service';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {ChangeDetectorRefHelper} from '../../../helpers/change-detector-ref.helper';
import {User} from '../../user/user';
import {Constants} from '../../../../constants';

interface Memo {
  leasedEmployee?: any;
  customer?: any;
  assignment?: any;
  order?: any;
  offer?: any;
  accommodation?: any;
  _embedded?: any;
}

export class MemoCreationService {

  public static getCorrectMasterEntityFqns(): string[] {
    return [
      'PhoenixBundle\\Entity\\LeasedEmployee',
      'PhoenixBundle\\Entity\\Customer',
      'PhoenixBundle\\Entity\\Assignment',
      'PhoenixBundle\\Entity\\Order',
      'PhoenixBundle\\Entity\\Offer',
      'PhoenixBundle\\Entity\\Accomodation',
      'PhoenixBundle\\Entity\\PreCalculation',
      'PhoenixBundle\\Entity\\Todo',
      'PhoenixBundle\\Entity\\CustomerTodo',
      'PhoenixBundle\\Entity\\LeasedEmployeeTodo'
    ];
  }

  public constructor(
    private entityManager: EntityManagerService,
    private genericCrud: GenericCrudService,
    private userSession: UserSessionService,
    private authenticationService: AuthenticationService
  ) {

  }

  public create(memoEntity: Memo, masterEntity: {fqn: string, customer?: any, leasedEmployee?: any}): any {
    return Observable.create((observer) => {
      const observables = [];

      const masterEntityFqn = masterEntity.fqn;

      observables.push(this.loadFormTypes());
      observables.push(this.loadCategories());
      observables.push(this.loadUser());

      observableForkJoin(observables).pipe(
        catchError((response: any) => {
          return observableThrowError(response);
        }),
        map((entitiesData: any[]) => {
          const categories: unknown[] = entitiesData[1];
          const formTypes: unknown[] = entitiesData[0];
          const user = entitiesData[2];

          const formType = this.findEntityByCode('ALL', formTypes);

          memoEntity._embedded = memoEntity._embedded || {};

          memoEntity = this.changeMemo(memoEntity, 'contactBranchOffice', this.userSession.get(Branch.LOCAL_STORAGE_NAME));
          memoEntity = this.changeMemo(memoEntity, 'contactUser', user);
          memoEntity = this.changeMemo(memoEntity, 'memoFormTyp', formType);

          switch (masterEntityFqn) {
            case 'PhoenixBundle\\Entity\\LeasedEmployee':
              memoEntity = this.changeMemo(memoEntity, 'leasedEmployee', masterEntity);
              memoEntity = this.changeMemo(memoEntity, 'memoCategory', this.findEntityByCode('MA', categories));

              observer.next(memoEntity);
              observer.complete();
              break;
            case 'PhoenixBundle\\Entity\\Customer':
              memoEntity = this.changeMemo(memoEntity, 'customer', masterEntity);

              memoEntity = this.changeMemo(memoEntity, 'memoCategory', this.findEntityByCode('KD', categories));

              observer.next(memoEntity);
              observer.complete();
              break;
            case 'PhoenixBundle\\Entity\\Assignment':
              memoEntity = this.changeMemo(memoEntity, 'assignment', masterEntity);
              memoEntity = this.changeCustomer(memoEntity, masterEntity);
              memoEntity = this.changeLeasedEmployee(memoEntity, masterEntity);

              observer.next(memoEntity);
              observer.complete();
              break;
            case 'PhoenixBundle\\Entity\\Order':
              memoEntity = this.changeMemo(memoEntity, 'order', masterEntity);
              memoEntity = this.changeCustomer(memoEntity, masterEntity);

              observer.next(memoEntity);
              observer.complete();
              break;
            case 'PhoenixBundle\\Entity\\Offer':
              memoEntity = this.changeMemo(memoEntity, 'offer', masterEntity);
              memoEntity = this.changeCustomer(memoEntity, masterEntity);

              observer.next(memoEntity);
              observer.complete();
              break;
            case 'PhoenixBundle\\Entity\\Accomodation':
              memoEntity = this.changeMemo(memoEntity, 'accomodation', masterEntity);

              observer.next(memoEntity);
              observer.complete();
              break;
            case 'PhoenixBundle\\Entity\\PreCalculation':
              memoEntity = this.changeMemo(memoEntity, 'preCalculation', masterEntity);
              memoEntity = this.changeCustomer(memoEntity, masterEntity);

              observer.next(memoEntity);
              observer.complete();
              break;
            case 'PhoenixBundle\\Entity\\Todo':
            case 'PhoenixBundle\\Entity\\CustomerTodo':
            case 'PhoenixBundle\\Entity\\LeasedEmployeeTodo':
              memoEntity = this.changeMemo(memoEntity, 'todo', masterEntity);
              memoEntity = this.changeCustomer(memoEntity, masterEntity);
              memoEntity = this.changeLeasedEmployee(memoEntity, masterEntity);

              /*if (masterEntityFqn === 'PhoenixBundle\\Entity\\Todo') {
                memoEntity = this.changeMemo(memoEntity, 'memoCategory', this.findEntityByCode('ALLG', categories));
              } else if (masterEntityFqn === 'PhoenixBundle\\Entity\\CustomerTodo') {
                memoEntity = this.changeMemo(memoEntity, 'memoCategory', this.findEntityByCode('KD', categories));
              } else {
                memoEntity = this.changeMemo(memoEntity, 'memoCategory', this.findEntityByCode('MA', categories));
              }*/

              if (memoEntity.leasedEmployee && memoEntity.leasedEmployee.id) {
                this.genericCrud.getEntity('phoenix/leasedemployees', memoEntity.leasedEmployee.id, null, {
                  embedded: 'mainAddress'
                }).subscribe(employee => {
                  const address = Entity.getValueInEmbedded(employee, 'mainAddress');

                  if (address) {
                    this.changeMemo(memoEntity, 'smsTo', address.fullMobileNumber);
                    this.changeMemo(memoEntity, 'email', address.primaryEmail);
                  }

                  observer.next(memoEntity);
                  observer.complete();
                });
              }

              break;
            default:
              observer.next(memoEntity);
              observer.complete();
          }
        })).subscribe();
    });
  }

  private loadCategories(): Observable<any[]> {
    return this.genericCrud.getEntities('phoenix/memocategories', '', {embedded: 'none'});
  }

  private findEntityByCode(code: string, entities: unknown[]): unknown|null {
    return entities.find(type => type['code'] === code);
  }

  private loadFormTypes(): Observable<any[]> {
    return this.genericCrud.getEntities('phoenix/memoformtyps', '', {embedded: 'none'});
  }

  private loadUser(): Observable<User> {
    return this.genericCrud.get(`${Constants.APP_API_ROUTE}/users/me?`, {
      'embedded': 'none'
    });
  }

  private changeMemo(memo: Memo|any, property: string, masterEntity: any): Memo {
    this.entityManager.persist(memo, {property: property, newValue: masterEntity, force: true});
    memo._embedded[property] = masterEntity;

    return memo;
  }

  private changeCustomer(memo: Memo, masterEntity: {_embedded?: any, customer?: any}): Memo {
    let customer = Entity.getValue(masterEntity, 'customer');

    if (!customer || typeof customer !== 'object') {
      customer = Entity.getValueInEmbedded(masterEntity, 'customer');
    }

    if (customer) {
      memo = this.changeMemo(memo, 'customer', customer);
    }

    return memo;
  }

  private changeLeasedEmployee(memo: Memo, masterEntity: {_embedded?: any, leasedEmployee?: any}): Memo {
    let leasedEmployee = Entity.getValue(masterEntity, 'leasedEmployee');

    if (!leasedEmployee || typeof leasedEmployee !== 'object') {
      leasedEmployee = Entity.getValueInEmbedded(masterEntity, 'leasedEmployee')
    }

    if (leasedEmployee) {
      memo = this.changeMemo(memo, 'leasedEmployee', leasedEmployee);
    }

    return memo;
  }
}
