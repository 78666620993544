<div class="ui-g-12 loading" *ngIf="isLoading">
  <app-generic-loading></app-generic-loading>
</div>

<div class="container" *ngIf="!isLoading">
  <div class="ui-g-12">
    <div class="ui-g-11"><strong>Aufgabenplanung</strong></div>
    <div class="ui-g-1 open">Offen: <strong>{{ openCount }}</strong></div>
  </div>
  <div class="ui-g-12">
    <div class="ui-g-10"><strong>Todo</strong></div>
    <div class="ui-g-2"><strong>Fertig</strong></div>
  </div>
  <ng-container *ngFor="let todo of todos; let i = index;">
    <div class="ui-g-12">
      <div class="ui-g-10">
        <input
          pInputText
          [placeholder]="'Name'"
          [(ngModel)]="todo.name"
          (change)="onSave(i, todo)"
        >
      </div>
      <div class="ui-g-2">
        <p-checkbox
          *ngIf="todo.id"
          binary="true"
          [(ngModel)]="!!todo.finishedAt"
          [disabled]="!!todo.finishedAt"
          (onChange)="onFinish(todo)"
        ></p-checkbox>
      </div>
    </div>
  </ng-container>

  <button
    type="button"
    pButton
    (click)="onAdd()"
    label="{{ 'COMMON.ADD' | translate }}"
  ></button>

</div>
