import {AbstractFormActionHandler} from '../abstract-form-action-handler';
import {Entity} from '../../../../helpers/entity';
import {FormViewerComponent} from '../../../form-viewer.component';
import {ElementContext, RuntimeFlagName} from '../../../../content-renderer/services/ElementContext';
import {EntityManagerService} from '../../../../../core/service/entity-manager/entity-manager.service';
import {ExecutorService} from '../../../../../core/executor/executor.service';
import {ExecutionStepFactoryService} from '../../../../../core/executor/factory/execution-step-factory.service';
import {
  SetupMemoFieldDefinitionValueComponentExecutionStep
} from '../../../../services/execution-step/memo/setup-memo-field-definition-value-component-execution.step';
import {ExecutionStepPayload} from '../../../../../core/executor/execution-step-payload';

export class SetupMemoFormActionHandler extends AbstractFormActionHandler {

  public constructor(
    private entityManager: EntityManagerService,
    private stepFactory: ExecutionStepFactoryService
  ) {
    super();
  }

  public handleAction(): void {
    const memoTypeMapping = {
      'createInCustomer': 'customer',
      'createInEmployee': 'leasedEmployee',
      'createInAssignment': 'assignment',
      'createInOrder': 'order',
      'createInOffer': 'offer',
      'createInAccomodation': 'accommodation',
      'createInPreCalculation': 'preCalculation'
    },
      memo = this.getEntity();

    if (this.isValid(memo)) {
      const memoType = Entity.getValue(memo, 'memoType') ||
        Entity.getValueInEmbedded(memo, 'memoType');

      this.handleText(memo, memoType);
      this.handleContext(memo, memoType, memoTypeMapping);
      this.handleMemoComponent();
    }
  }

  private handleText(memo, memoType): this {
    const formComponent = this.getComponent();

    if (formComponent instanceof FormViewerComponent && formComponent.getElementContext() && memoType.text) {
      const formContext: ElementContext = formComponent.getElementContext(),
        textFormElement = this.formService.getElementBy(this.getForm(), 'datamodelField', 'text'),
        textFormControl = this.getFormGroup().get(textFormElement.datamodelField + '_h_r_f_e_' + textFormElement.objectHashId);

      if (formContext.getRuntimeFlag(RuntimeFlagName.IsMemoTypeTextDefinedOnLoad) === null) {
        formContext.addRuntimeFlag({
          name: RuntimeFlagName.IsMemoTypeTextDefinedOnLoad,
          status: Entity.getValue(memo, 'text') !== null,
          active: true
        });
      }

      if (textFormElement && textFormControl && formContext.getRuntimeFlagStatus(RuntimeFlagName.IsMemoTypeTextDefinedOnLoad) === false && memoType.id !== formContext.getRuntimeFlagValue(RuntimeFlagName.IsMemoTypeTextDefinedOnLoad)) {
        textFormElement.setValue(memoType.text);
        textFormControl.setValue(memoType.text);
        this.entityManager.persist(memo, {property: 'text', newValue: memoType.text, force: true });
        formContext.setRuntimeFlagValue(RuntimeFlagName.IsMemoTypeTextDefinedOnLoad, memoType.id);
      }
    }

    return this;
  }

  private handleContext(memo, memoType, memoTypeMapping): this {
    this.hideAll(memoTypeMapping)
      .showBasedOnMemo(memo, memoType, memoTypeMapping);

    return this;
  }

  private handleMemoComponent(): this {
    const executorService = new ExecutorService();

    executorService.setSteps([
      this.stepFactory.create(SetupMemoFieldDefinitionValueComponentExecutionStep, new ExecutionStepPayload(
        this.getComponent()
      ))
    ])
    .execute()
    .subscribe();

    return this;
  }

  private hideAll(memoTypeMapping): this {
    for (const memoTypeProperty in memoTypeMapping) {
      if (memoTypeMapping.hasOwnProperty(memoTypeProperty)) {
        const masterEntity = memoTypeMapping[memoTypeProperty],
          formElement = this.formService.getElementBy(this.getForm(), 'datamodelField', masterEntity);

        if (formElement) {
          formElement.isHidden = true;
          this.markElementForCheck(formElement);
        }
      }
    }

    return this;
  }

  private showBasedOnMemo(memo, memoType, memoTypeMapping): this {
    for (const memoTypeProperty in memoTypeMapping) {
      if (memoTypeMapping.hasOwnProperty(memoTypeProperty) && memoType.hasOwnProperty(memoTypeProperty)) {
        const masterEntity = memoTypeMapping[memoTypeProperty];

        const value = Entity.getValue(memoType, memoTypeProperty),
          formElement = this.formService.getElementBy(this.getForm(), 'datamodelField', masterEntity);

        if (formElement) {
          formElement.isHidden = value !== true;
          this.markElementForCheck(formElement);
        }
      }
    }

    return this;
  }

  private isValid(memo): boolean {
    let isValid = false;

    if (memo) {
      const memoType = Entity.getValue(memo, 'memoType') ||
        Entity.getValueInEmbedded(memo, 'memoType');

      if (memoType) {
        isValid = true;
      }
    }

    return isValid;
  }
}
