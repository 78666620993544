import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {GenericCrudService} from '../../services/generic-crud.service';
import {Organisation} from '../../services/organisation/organisation';
import {Entity} from '../../helpers/entity';
import {ChangeDetectorRefHelper} from '../../helpers/change-detector-ref.helper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-generic-dropdown',
  templateUrl: './generic-dropdown.component.html'
})
export class GenericDropdownComponent implements OnInit {

  @Input() public selectedOption: SelectItem = null;
  @Input() public forceSelectionOnLoad = false;
  @Input() public selectFirstOnLoadIfOnlyOne = false;
  @Input() public valueAsId = false;
  @Input() public api = '';
  @Input() public labelKey = 'name';
  @Input() public disabled = false;
  @Input() public options: SelectItem[] = [];
  @Input() public showEmptyOption = false;

  @Output() public optionChanged: EventEmitter<SelectItem> = new EventEmitter();

  public constructor(
    protected readonly genericCrudService: GenericCrudService,
    public cdr: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {

    this.loadOptions()
      .subscribe((organisations: any[] = []) => {
        this.options = [];

        if (this.showEmptyOption) {
          this.options = [...this.options, {
            label: '---',
            value: null
          }]
        }

        for (const organisation of organisations) {
          const value = this.valueAsId ? organisation.id : organisation;

          this.options = [...this.options, {
            label: Entity.getValue(organisation, this.labelKey) || Entity.getValueInEmbedded(organisation, this.labelKey),
            value: value
          }];
        }

        if (this.forceSelectionOnLoad) {
          this.forceSelection();
        }

        if (this.selectFirstOnLoadIfOnlyOne) {
          this.selectFirstOptionOnLoadIfOnlyOne()
        }
      });
  }

  public onOptionChanged(event?: {value: any}): void {
    this.optionChanged.emit(this.selectedOption);
  }

  private loadOptions(): Observable<Organisation[]> {
    return this.genericCrudService.getEntities(this.api);
  }

  private forceSelection(): void {
    for (const option of this.options) {
      if (!option.value) {
        continue;
      }
      if (this.forceSelectionOnLoad && this.selectedOption && this.selectedOption.value &&
        this.selectedOption.value.id === option.value.id
      ) {
        this.selectedOption = option.value;
        ChangeDetectorRefHelper.detectChanges(this);
      }
    }
  }

  private selectFirstOptionOnLoadIfOnlyOne(): void {
    if (this.options.length === 1) {
      this.selectedOption = this.options[0].value
      this.onOptionChanged(this.selectedOption);
    }
  }
}

