import {Observable, of as observableOf} from 'rxjs';

import {map} from 'rxjs/operators';
import {AbstractExecutionStep} from '../../../../core/executor/abstract-execution-step';
import {ExecutionStepStatus} from '../../../../core/executor/execution-step-status';
import {MessageGrowlService} from '../../../../core/message/message-growl.service';
import {TOAST_LIFE_INFINITE, ToastComponentsRegistry, ToastService} from '../../../../core/service/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {FormViewerComponent} from '../../../form-viewer/form-viewer.component';
import {RequestCachingService} from '../../request-caching.service';
import {environment} from '../../../../../environments';
import {Router} from '@angular/router';

export class InvoiceToolbarItemUnlockInvoiceExecutionStep extends AbstractExecutionStep {

  public doExecute(): Observable<ExecutionStepStatus> {
    const payload = this.getPayload(),
      component = payload.getValue().component ? payload.getValue().component : payload.getValue();

    if (component instanceof FormViewerComponent && (!component.getSelectedEntity() ||
      !component.getSelectedEntity().id)
    ) {
      return observableOf({status: false, content: 'Can unlock or check only existing entities!' });
    }

    if (component instanceof FormViewerComponent) {
      this.doPrint(component);
      return this.doSet(component);
    }

    return observableOf({status: false, content: 'Not configured correctly!' });
  }

  private doSet(component: FormViewerComponent): Observable<ExecutionStepStatus> {
    const entity = component.getSelectedEntity();
    const moduleState = this.modulesStateService.getByComponent(component);

    this.getToast().custom(ToastComponentsRegistry.PROGRESS_BAR, {
      severity: 'info',
      life: TOAST_LIFE_INFINITE,
      closable: false,
      summary: this.getTranslate().instant('COMMON.SAVING_PLEASE_WAIT')
    });


    return this.genericCrudService.editEntity(
      `phoenix/invoices/unlock/${entity.id}`, {}
    ).pipe(
    map((invoice) => {

      component.setEntity(invoice);
      component.onRefresh().subscribe();

      this.getToast().clear(ToastComponentsRegistry.PROGRESS_BAR, 300);
      this.getGrowl().showDataSaved();

      if (moduleState.main) {
        this.modulesStateService.remove(moduleState);
        this.modulesStateService.show(moduleState.main);
        this.injector.get(Router).navigate([moduleState.main.url]);
      }

      return {status: true, content: null };
    }));
  }

  private doPrint(component: FormViewerComponent): void {
    this.doExport('pdf', component).subscribe((fileResponse) => {
      this.getRequestCachingService().removeByExpression('phoenix');
    });
  }

  private doExport(key: string, component): Observable<any> {
    const entity = component.getSelectedEntity();
    if (entity && entity.id) {
      const datamodelId = component.getElementDatamodel().id;
      const url = `app/export/entity/${datamodelId}/file/${entity.id}/template/188/${key}`;

      return this.genericCrudService.customPost(url, {});
    }
  }

  private getGrowl(): MessageGrowlService|null {
    return this.injector.get(MessageGrowlService, null);
  }

  private getToast(): ToastService|null {
    return this.injector.get(ToastService, null);
  }

  private getTranslate(): TranslateService|null {
    return this.injector.get(TranslateService, null);
  }

  private getRequestCachingService(): RequestCachingService|null {
    return this.injector.get(RequestCachingService, null);
  }
}
