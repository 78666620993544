import { AbstractExecutionStep } from '../../../core/executor/abstract-execution-step';
import { Observable } from 'rxjs';
import { ExecutionStepStatus } from '../../../core/executor/execution-step-status';
import { GenericElementAbstract, ElementSaveStatus } from '../../content-renderer/elements/generic-element-abstract.component';

export class ComponentSaveExecutionStep extends AbstractExecutionStep {

    public doExecute(): Observable<ExecutionStepStatus> {
        const payload = this.getPayload();

        if (!(payload.getValue() instanceof GenericElementAbstract)) {
            return this.getFailObservable('You need to pass GenericElementAbstract as Payload value!');
        }

        return this.doSave(payload.getValue());
    }

    private doSave(component: GenericElementAbstract): Observable<ExecutionStepStatus> {

        return Observable.create(observer => {
            component.onSave()
                .subscribe((saveStatus: ElementSaveStatus) => {
                      observer.next({
                        status: saveStatus.status,
                        content: saveStatus.content
                    });
                    observer.complete();
                });
        });
    }
}
